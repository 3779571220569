import React, { FC, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { Deadline } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components";
import { Calendar } from "@shared/planningWork";
import { Colors } from "@theme/colors";
import { Billet, TextFont, ModalUI, HeaderModal } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { toFormatDate, toFormatISO } from "@utils/toFormatTime";

import { titleType } from "../../constants";

import { DayOfWeek, EmptyCalendar, RepeatInfo, RepeatType } from "./components";
import { arrayPostpone, configPeriod } from "./config";
import { useData } from "./useData";
import { renderResult } from "./utils";

interface IProps {
  close: () => void;
  meet: IMeet;
  changeMeet: (name: string, value: any) => void;
}

export const Repeat: FC<IProps> = ({ close, meet, changeMeet }) => {
  const {
    data,
    handleData,
    isCancel,
    setIsCancel,
    isNotValidPeriod,
    isEdit,
    isVisibleType,
    setIsVisibleType,
    isRenderResult,
    dateNow,
    setData,
  } = useData({
    meet,
  });

  const handleSave = () => {
    changeMeet("repeat", {
      ...data,
      postpone: data.type === "WEEK" ? null : data.postpone,
      endTime: data.endTime ? toFormatISO(dayjs(`${data.endTime}23:59`)) : null,
    });
    close();
  };

  const onPressDay = (dateWeek: Dayjs) => {
    let tempDays = [...(data.days ?? [])];

    if (data.days?.includes(toFormatDate(dateWeek))) {
      tempDays = tempDays.filter((i) => i !== toFormatDate(dateWeek));
    } else {
      tempDays = [...tempDays, toFormatDate(dateWeek)];
    }
    handleData("days", tempDays);
  };

  const reset = () => {
    changeMeet("repeat", null);
    close();
  };

  const handleInputPeriod = (value: string, max: number) => {
    if (Number(value) > max) {
      handleData("repetitionPeriod", max);
    } else {
      handleData("repetitionPeriod", value);
    }
  };

  const propsForCalendar = {
    type: "multituple",
    days: data.days ? data.days.map((i) => ({ date: i })) : [],
    press: onPressDay,
    currentDay: toFormatDate(dateNow),
    startDay: toFormatDate(dateNow),
    deadline: toFormatDate(dayjs(data.endTime ?? "")),
  };

  return (
    <ModalUI isVisible={true} onClose={isEdit ? () => setIsCancel(true) : () => close()}>
      <HeaderModal
        title="repeat"
        leftSide={{ onPressClose: close, onPressCancel: () => setIsCancel(true) }}
        rightSide={{ onPress: handleSave }}
        isEdit={isEdit}
      />

      <ContentWrap>
        <Billet textId={titleType[data?.type ?? "WEEK"]} onPress={() => setIsVisibleType(true)} />

        <RenderWithCondition condition={data.type !== "YEAR"}>
          <LineWeek>
            <TextFont size={18}>{configPeriod[data.type]?.every}</TextFont>
            <InputWeek
              value={String(data.repetitionPeriod)}
              maxLength={2}
              max={configPeriod[data.type]?.max}
              min={1}
              type="number"
              isNotValidPeriod={isNotValidPeriod}
              onChange={(e) => handleInputPeriod(e.target.value, configPeriod[data.type]?.max as number)}
            />
            <TextFont size={18}>{configPeriod[data.type]?.subject}</TextFont>
          </LineWeek>
        </RenderWithCondition>

        <RenderWithCondition condition={data?.type === "WEEK"}>
          <DayOfWeek handleData={handleData} days={data?.dayOfWeeks ?? []} />
        </RenderWithCondition>

        <RenderWithCondition condition={data?.type !== "WEEK"}>
          <div>
            <TextDateRepeat size={18} type="bold">
              Выберите даты повтора
            </TextDateRepeat>
            {data.type === "MONTH" ? <EmptyCalendar {...propsForCalendar} /> : <Calendar {...propsForCalendar} />}
          </div>
        </RenderWithCondition>

        <Deadline
          handleData={handleData}
          date={data.endTime ?? ""}
          startDate={toFormatDate(dateNow)}
          isVisibleTooltip={false}
          field="endTime"
          titleId="repeatAbout"
        />
        <RenderWithCondition condition={data?.type !== "WEEK"}>
          <RepeatInfo textInfo="infoForRepeatMeetings" />
        </RenderWithCondition>

        <RenderWithCondition condition={isRenderResult}>
          <div>
            <TextResult type="bold" size={18}>
              <FormattedMessage id="whatResult" />:
            </TextResult>
            <TextFont size={18} lineHeight={24}>
              {renderResult({
                type: data.type,
                endTime: data.endTime,
                days: data.dayOfWeeks,
                daysRepeat: data?.days ?? [],
                period: data.repetitionPeriod,
              })}
            </TextFont>
          </div>
        </RenderWithCondition>

        <RenderWithCondition condition={data?.days?.length && data.type !== "WEEK"}>
          <div>
            <TextFont size={18} type="bold">
              <FormattedMessage id="weekendMeetingDescription" />
            </TextFont>

            <PostponeWrap>
              {arrayPostpone.map((postpone) => (
                <ButtonRadio key={postpone.title} onClick={() => handleData("postpone", postpone.value)}>
                  <TextFont size={18}>
                    <FormattedMessage id={postpone.title} />
                  </TextFont>
                  <InputRadio
                    type="radio"
                    onClick={() => handleData("postpone", postpone.value)}
                    value={postpone.value}
                    checked={postpone.value === data.postpone}
                  />
                </ButtonRadio>
              ))}
            </PostponeWrap>
          </div>
        </RenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleType}>
          <RepeatType changeMeet={setData} close={() => setIsVisibleType(false)} type={data.type} reset={reset} />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isCancel}>
          <NotificationConfirm phraseId="cancelException" onOk={close} onCancel={() => setIsCancel(false)} />
        </ModalRenderWithCondition>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 10px 12px 0 12px;
  min-height: 95%;
  overflow: auto;
`;
const LineWeek = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;
const PostponeWrap = styled.div`
  margin-top: 12px;
`;
const TextDateRepeat = styled(TextFont)`
  margin-bottom: 12px;
`;
const TextResult = styled(TextFont)`
  margin-bottom: 6px;
`;
const ButtonRadio = styled.button`
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const InputWeek = styled.input<{ isNotValidPeriod?: boolean }>`
  width: 79px;
  height: 48px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 10px;
  text-align: center;
  padding-left: 12px;
  font-size: 18px;
  border: 1px solid ${Colors.LIGHT.background.grey};

  ${({ isNotValidPeriod }) =>
    isNotValidPeriod &&
    `
  border-color:  ${Colors.LIGHT.red};
`}
`;
const InputRadio = styled.input`
  accent-color: ${Colors.LIGHT.green};
`;

import React, { FC, memo, useMemo, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SvgCopy } from "@assets/copy_double.svg";
import { ReactComponent as SvgEdit } from "@assets/edit_underline.svg";
import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { regExpUrl } from "@constants/regExp";
import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { SwipeList } from "@screens/day/components/ListTasks";
import { Colors } from "@theme/colors";
import { ArrowSvg, TaskActions, TextFont } from "@ui";
import { showToast } from "@utils/toast";

import { IStartData } from "../../activityParticipants/ActivityParticipants";
import { ModalPlacement } from "../modalPlacement/ModalPlacement";

import {
  ButtonBillet,
  ButtonItem,
  EmptyElement,
  HeaderPlace,
  LineIcons,
  LineItems,
  LinkItem,
  LinksWrap,
  ListRooms,
  TextTitlePlace,
} from "./styled";

interface IProps {
  places: string[];
  handleData: (name: string, value: unknown) => void;
  allowEdit?: boolean;
  startData: IStartData;
  isButtonAdd?: boolean;
  meet: IMeet;
}

export const Placement: FC<IProps> = ({ places = [], handleData, allowEdit, startData, isButtonAdd = true, meet }) => {
  const [currentPlace, setCurrentPlace] = useState<{ title: string; index: number; id: string } | undefined>(undefined);

  const handleRemove = (id: string) => {
    if (places.includes(id)) {
      handleData(
        "places",
        [...places].filter((i) => i !== id),
      );
    } else {
      handleData("meetingRoomId", null);
    }
  };

  const handleAdd = (place: string, index: number) => {
    if (index === -1) {
      handleData("places", [...places, place]);
    } else {
      const temp = [...places];
      temp.splice(index, 1, place);
      handleData("places", temp);
    }
  };

  const buttons = ({ item, pos, isVisible: isVisibleActions }) =>
    isVisibleActions && <TaskActions pos={pos} configuration={{ delete: { onClick: () => handleRemove(item?.id) } }} />;

  const msgMeetOnline = useIntl().formatMessage({ id: "meetPlaceOnline" });
  const msgMeetPlaceZoom = useIntl().formatMessage({ id: "meetPlaceZoom" });
  const msgMeetPlaceGoogle = useIntl().formatMessage({ id: "meetPlaceGoogle" });
  const msgMeetPlaceTelemost = useIntl().formatMessage({ id: "meetPlaceTelemost" });
  const msgMeetPlaceSkype = useIntl().formatMessage({ id: "meetPlaceSkype" });

  const getMeetPlaceName = (meetPlace: string) => {
    const isLink = meetPlace?.match(regExpUrl);

    if (!isLink) {
      return meetPlace;
    }

    const domain = meetPlace?.match(/https?:\/\/(?<domain>[^:/?,]*)/)![0];

    if (domain?.includes("zoom")) {
      return msgMeetPlaceZoom;
    }
    if (meetPlace?.includes("meet.google")) {
      return msgMeetPlaceGoogle;
    }
    if (meetPlace?.includes("telemost")) {
      return msgMeetPlaceTelemost;
    }
    if (meetPlace?.includes("join.skype")) {
      return msgMeetPlaceSkype;
    }

    return msgMeetOnline;
  };

  const totalListRooms = useMemo(() => {
    const virtualRooms = places?.map((i) => ({ id: i, title: getMeetPlaceName(i) })) ?? [];
    if (meet.meetingRoom) {
      return [...virtualRooms, { id: meet.meetingRoom?.id, title: meet.meetingRoom?.name }];
    }
    return virtualRooms;
  }, [places, meet]);

  return (
    <section>
      <HeaderPlace>
        <TextFont type="bold" size={18} weight="700">
          <FormattedMessage id="meetPlace" />
        </TextFont>
        {allowEdit && (
          <button disabled={totalListRooms?.length > 2} onClick={() => setCurrentPlace({ title: "", index: -1, id: meet.meetingRoom?.id })}>
            <SvgPlus width={16} height={16} fill={totalListRooms?.length > 2 ? Colors.LIGHT.lighGrey : Colors.LIGHT.background.grey} />
          </button>
        )}
      </HeaderPlace>

      {!!totalListRooms.length && (
        <ListRooms>
          <SwipeList
            data={totalListRooms}
            element={(data: { item; index; handleRightClick }) => {
              const isLink = data.item.title?.match(regExpUrl);

              return (
                <ButtonItem onContextMenu={(e) => allowEdit && data.handleRightClick(e, data.item?.id)}>
                  <LineItems>
                    <TextTitlePlace type="bold" weight="700" size={16} lineHeight={24}>
                      {getMeetPlaceName(data.item.title)}
                    </TextTitlePlace>
                    <LineIcons>
                      {allowEdit && (
                        <button onClick={() => setCurrentPlace({ index: data.index, title: data.item.title, id: data?.item?.id })}>
                          <SvgEdit />
                        </button>
                      )}
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(data.item.title);
                          showToast("textCopied");
                        }}
                      >
                        <SvgCopy />
                      </button>
                    </LineIcons>
                  </LineItems>
                  {isLink && (
                    <LinksWrap>
                      <LinkItem target="_blank" href={data.item.title}>
                        {data.item.title}
                      </LinkItem>
                      <EmptyElement />
                    </LinksWrap>
                  )}
                </ButtonItem>
              );
            }}
            style={{ marginHorizontal: -13 }}
            buttons={buttons}
          />
        </ListRooms>
      )}

      <RenderWithCondition condition={totalListRooms?.length < 3 && isButtonAdd}>
        <ButtonBillet onClick={() => setCurrentPlace({ title: "", index: -1, id: meet?.meetingRoom?.id })}>
          <TextFont size={16} color={Colors.LIGHT.text.accent} weight="400">
            {"+ "}
            <FormattedMessage id="addMeetPlace" defaultMessage={"Добавить место встречи / ссылку"} />
          </TextFont>
          <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
        </ButtonBillet>
      </RenderWithCondition>

      {currentPlace !== undefined && (
        <ModalPlacement
          currentPlace={currentPlace}
          places={places}
          meet={meet}
          onClose={() => setCurrentPlace(undefined)}
          onSave={handleAdd}
          allowEdit={allowEdit}
          startData={startData}
        />
      )}
    </section>
  );
};

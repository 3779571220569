import React, { FC, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { isTablet } from "@utils/index";

import { HEIGHT_BLOCK, WIDTH_BLOCK } from "../constants";

interface IRow {
  hour: number;
  date: Dayjs;
  rows: number;
  isLightColumnNumber: number;
}

export const Row: FC<IRow> = ({ hour, date, rows, isLightColumnNumber }) => {
  const currentMinute = dayjs().minute();

  const isCurrentHour = useMemo(() => hour === dayjs().hour() && date.isSame(dayjs(), "date"), [date, hour]);

  const dynamicPadding = useMemo(() => {
    if (currentMinute >= 0 && currentMinute <= 15) {
      return 0;
    }

    if (currentMinute > 15 && currentMinute <= 30) {
      return 7;
    }

    if (currentMinute > 30 && currentMinute <= 60) {
      return 15;
    }
  }, [currentMinute]);

  const formatHour = useMemo(() => (hour < 10 ? `0${hour}` : hour), [hour]);

  return (
    <ContentWrap>
      <TextHour size={14} color={Colors.LIGHT.text.grey400}>
        {formatHour}
      </TextHour>

      <div>
        {[...Array(rows).keys()].map((i) => (
          <ColumnItem isTablet={isTablet} isActive={isLightColumnNumber === i} key={i} />
        ))}
      </div>

      <RenderWithCondition condition={isCurrentHour}>
        <BlockCurrentTime paddingLeft={dynamicPadding}>
          <CircleTopTime />
          <LineTime />
        </BlockCurrentTime>
      </RenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  position: relative;
  width: ${WIDTH_BLOCK}px;
`;
const CircleTopTime = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background-color: ${Colors.LIGHT.red};
`;
const LineTime = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${Colors.LIGHT.red};
`;
const BlockCurrentTime = styled.div<{ paddingLeft: number }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;
  z-index: 3;
  height: 300%;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
`;
const TextHour = styled(TextFont)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6px 0;
  width: ${WIDTH_BLOCK}px;
  height: 56px;
`;
const ColumnItem = styled.div<{ isTablet: boolean; isActive: boolean }>`
  height: ${HEIGHT_BLOCK}px;
  width: ${WIDTH_BLOCK}px;
  border-right: 1px solid ${Colors.LIGHT.lighGrey};
  border-top: ${({ isTablet }) => (isTablet ? "1px solid ${Colors.LIGHT.lighGrey}" : "0")};
  ${({ isActive }) =>
    isActive &&
    `
  background-color: ${Colors.LIGHT.lightGrey300};
   opacity: 0.5`}
`;

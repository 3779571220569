import React, { FC, memo, useEffect, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { PlanningWork } from "@shared";
import { AttachFileContextMenuButton } from "@shared/fileAttachments";
import { clone, deleteTask, close as closeTask, update, pause } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { selectUserPermissoins, userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { Notification } from "@ui/notification/Notification";
import { TextFont } from "@ui/TextFont";

import { ModalClose } from "../../../../components/modalClose/ModalClose";
import { ButtonDelegate } from "../../delegate";
import { TaskMeetings } from "../../taskMeetings/TaskMeeting";
import { ModalGoal } from "../../ViewNotSave/components";
import { ModalProject } from "../../ViewNotSave/components/project/modalProject/ModalProject";
import { ModalComment } from "../components/ModalComment";

import { TKeyButtons, config } from "./config";

interface IActions {
  task: ITask;
  close: () => void;
  closeOverModal: () => void;
  isPersonalTask: boolean;
  isFromOneS: boolean;
  openFilePicker: () => void;
  handleTakeBack: () => void;
}

export const Actions: FC<IActions> = memo(function Actions({
  task,
  isPersonalTask = false,
  isFromOneS = false,
  close,
  closeOverModal,
  openFilePicker,
  handleTakeBack,
}) {
  const dispatch = useDispatch<any>();
  const screen = window.location.pathname;
  const { currentUser } = userSelector();
  const isAuthor = currentUser?.id === task.creator;

  const [isVisible, setIsVisible] = useState<Record<TKeyButtons, boolean>>({
    close: false,
    copy: false,
    deadline: false,
    remove: false,
    takeBack: false,
    project: false,
    goal: false,
    pause: false,
  });

  useEffect(() => {
    document.getElementById("modalBusinessTask").addEventListener("click", (e) => {
      if (!document.getElementById("businessTaskActions")?.contains(e?.target)) {
        close();
      }
    });
  }, []);

  const permissions = useSelector(selectUserPermissoins);
  const dateNow = useSelector(selectCurrentDate);

  const handleVisble = (key: TKeyButtons, value: boolean) => {
    setIsVisible({ ...isVisible, [key]: value });
  };

  const handleCopy = () => {
    dispatch(clone({ id: task.id, isPersonalTask }));
    handleVisble("copy", false);
  };

  const handleDelete = async () => {
    await dispatch(deleteTask({ id: task.id, isPersonalTask }));
    closeOverModal();
  };

  const handleClose = async (reason: string, comment: string) => {
    dispatch(closeTask({ amount: { id: task.id, reason, comment }, isPersonalTask }));
    if (screen !== "/tasks") {
      closeOverModal();
    }
    close();
  };

  const handleGoalAndProject = async (name: string, value: any) => {
    await dispatch(update({ id: task.id, isPersonalTask, data: { [name]: value } }));
    close();
  };

  const handleDeadline = async (days: WorkDay[]) => {
    await dispatch(update({ id: task.id, isPersonalTask, data: { plannedEndDate: days[0].date } }));
    close();
  };

  const handlePause = async (comment: string) => {
    const workDay = task.workDays?.filter((i) => dateNow.isSame(i.date, "date"))[0];
    await dispatch(pause({ id: task.id, comment, isPersonalTask, isDeadline: !!task.plannedEndDate, isStatusWorkDay: !!workDay?.status }));
    close();
  };

  const handlers: Record<TKeyButtons, () => void> = {
    copy: handleCopy,
    remove: () => handleVisble("remove", true),
    close: () => handleVisble("close", true),
    goal: () => handleVisble("goal", true),
    project: () => handleVisble("project", true),
    deadline: () => handleVisble("deadline", true),
    takeBack: handleTakeBack,
    pause: () => handleVisble("pause", true),
  };

  const isTaskNotCompletedAndNotClosed = useMemo(
    () => task.status !== "CLOSED" && task.status !== "COMPLETED" && task.status !== "PAUSED",
    [task.status],
  );
  const shouldRenderTaskMeetings = useMemo(
    () => isTaskNotCompletedAndNotClosed && !task.controlledTask && !isPersonalTask,
    [isTaskNotCompletedAndNotClosed, task.controlledTask, isPersonalTask],
  );
  const isRenderDelegateTo = useMemo(
    () =>
      !task.delegatedToUser &&
      isTaskNotCompletedAndNotClosed &&
      permissions.CAN_DELEGATE &&
      task.type !== "CONTROL" &&
      !isPersonalTask &&
      !isFromOneS,
    [task.delegatedToUser, task.type, isTaskNotCompletedAndNotClosed, permissions.CAN_DELEGATE, isPersonalTask],
  );
  const isRenderDelegateFrom = useMemo(
    () => task.delegatedToUser && isTaskNotCompletedAndNotClosed,
    [task.delegatedToUser, isTaskNotCompletedAndNotClosed],
  );

  const renderItem = (key: TKeyButtons, has = false) => {
    const item = (
      <div className="action">
        <ButtonItem onClick={handlers[key]}>
          {config[key].icon}
          <TextFont size={16}>
            <FormattedMessage id={config[key][has ? "editTitle" : "title"]} />
          </TextFont>
        </ButtonItem>
      </div>
    );

    switch (task.status) {
      case "OPEN":
        return item;
      case "CLOSED":
        if (config[key].allowForComplete) {
          return item;
        }
        return null;

      case "COMPLETED":
        if (config[key].allowForComplete) {
          return item;
        }
        return null;

      case "PAUSED":
        if (config[key].allowForPause) {
          return item;
        }
        return null;

      case "EXPIRED":
        return item;

      default:
        return item;
    }
  };

  return (
    <ContentWrap id="businessTaskActions">
      <RenderWithCondition condition={isRenderDelegateTo}>
        <div className="action">
          <ButtonDelegateItem task={task} sizeIcon={16} />
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={isRenderDelegateFrom}>
        <>{renderItem("takeBack")}</>
      </RenderWithCondition>
      <RenderWithCondition condition={shouldRenderTaskMeetings}>
        <div className="action">
          <TaskMeetings taskId={task.id} closeOverModal={close} onlyButton allowMeetingsAttach />
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={task.status !== "PAUSED" && !isFromOneS}>
        <>{renderItem("pause")}</>
      </RenderWithCondition>
      <RenderWithCondition condition={isTaskNotCompletedAndNotClosed && !isFromOneS}>
        <div className="action">
          <AttachFileContextMenuButton openFilePicker={openFilePicker} />
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={!isPersonalTask && !isFromOneS}>
        <>{renderItem("goal", !!task.businessGoal)}</>
      </RenderWithCondition>
      <RenderWithCondition condition={permissions.WORK_WITH_SPACE_PROJECTS && !isPersonalTask && !isFromOneS}>
        <>{renderItem("project", !!task.project)}</>
      </RenderWithCondition>
      <RenderWithCondition condition={!isFromOneS}>{renderItem("deadline", !!task.plannedEndDate)}</RenderWithCondition>
      <RenderWithCondition condition={!isVisible.copy && !task.controlledTask && !isFromOneS}>
        <>{renderItem("copy")}</>
      </RenderWithCondition>
      <RenderWithCondition condition={!isFromOneS}>{renderItem("close")}</RenderWithCondition>
      <RenderWithCondition condition={!isFromOneS && isAuthor}>
        <div className="action">
          <ButtonRemove onClick={handlers.remove}>
            {config.remove.icon}
            <TextFont size={16} color={Colors.LIGHT.red}>
              <FormattedMessage id={config.remove.title} />
            </TextFont>
          </ButtonRemove>
        </div>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible.remove}>
        <Notification
          phraseId="deleteNotice"
          phraseOkId="deleteContinue"
          type="confirm"
          onOk={handleDelete}
          onCancel={() => handleVisble("remove", false)}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisible.copy}>
        <Notification type="success" phraseId="Задача скопирована" />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisible.close}>
        <ModalClose isVisible={isVisible.close} setIsVisible={() => handleVisble("close", false)} onSave={handleClose} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisible.goal}>
        <ModalGoal
          isVisible={isVisible.goal}
          close={() => handleVisble("goal", false)}
          goalId={task.businessGoal?.id}
          setGoal={handleGoalAndProject}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisible.pause}>
        <ModalComment close={() => handleVisble("pause", false)} onSave={handlePause} title="Приостановить задачу" />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisible.project}>
        <ModalProject
          isVisible={isVisible.project}
          close={() => handleVisble("project", false)}
          handleProject={handleGoalAndProject}
          projectName={task.project?.name}
          title={"projects"}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisible.deadline}>
        <PlanningWork
          isVisible={isVisible.deadline}
          setIsVisible={() => handleVisble("deadline", false)}
          isTime={false}
          isMultiple={false}
          startDays={[{ date: task.plannedEndDate }]}
          onSave={handleDeadline}
          startDate={dateNow.format("YYYY-MM-DD")}
          keyTitle="setDeadline"
          selectedWorkDay={task.plannedEndDate}
          disableAddTimeToggle
        />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
});

const ContentWrap = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  border-radius: 8px;
  box-shadow: 0 2px 6px ${Colors.LIGHT.shadowTooltip};
  background-color: ${Colors.LIGHT.white};
  z-index: 999;
`;
const ButtonItem = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 6px;
`;
const ButtonRemove = styled.button`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  gap: 6px;
  width: 100%;
  border-top: 1px solid ${Colors.LIGHT.lighGrey};
`;
const ButtonDelegateItem = styled(ButtonDelegate)`
  padding: 8px;
  border-radius: 0;
  margin-top: 0;
`;

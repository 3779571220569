import React, { FC, Dispatch, useEffect, useState, useRef, useCallback } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Svg1C } from "@assets/1C.svg";
import { ReactComponent as SvgOutlook } from "@assets/outlook.svg";
import { ReactComponent as SvgQuestion } from "@assets/question.svg";
import { ReactComponent as SvgZoom } from "@assets/zoom.svg";
import { RemindMeetings } from "@components/screens/profile/components/Settings/components/RemindMeetings";
import { LoaderRenderWithCondition } from "@hoc";
import { IStateUser } from "@interfaces/user.interface";
import { api, StoreTagTypes } from "@services/api";
import { useGetOutlookAuthStatusQuery, useRevokeAuthOutlookMutation } from "@services/userApi";
import { useGetZoomAuthStatusQuery, useGetZoomInfoQuery, useRevokeAuthZoomMutation } from "@services/userApi";
import { userSettings } from "@services/userSettings.service";
import { setIsHidePersonalTasks } from "@store/calendar/slice";
import { userSelector } from "@store/user";
import { selectIsLoadingUserSettings, selectShowBusiness, selectShowPersonal, selectUserPersonalSettings } from "@store/userSettings";
import { getPersonalSettings, updatePersonalSettings } from "@store/userSettings/api";
import { Colors } from "@theme/colors";
import { HeaderModal, TextFont } from "@ui";
import { isTablet } from "@utils/isTablet";

import { TypeTab } from "../../types";
import { ZoomSuccess } from "../ZoomSuccess";

import { AuthOutlook } from "./components/AuthOutlook";
import { SettingsOptionItem } from "./components/SettingsOptionItem";
import { LinkToZoom } from "./linkToZoom/LinkToZoom";

interface ISettings {
  onClose: () => void;
  setTab?: Dispatch<TypeTab>;
  user: IStateUser;
}

export const Settings: FC<ISettings> = ({ onClose, user }) => {
  const personalSettings = useSelector(selectUserPersonalSettings);
  const [params] = useSearchParams();
  const location = useLocation();

  const [isVisibleOutlock, setIsVisibleOutlock] = React.useState(false);
  const [activeTab, setActiveTab] = useState<"meets" | "integrations">("meets");
  const [isToggle1Enabled, setIsToggle1Enabled] = useState(true);
  const [isToggle2Enabled, setIsToggle2Enabled] = useState(true);
  const [isCancelNotificationsPastMeetings, setCancelNotificationsPastMeetings] = useState<boolean>(
    personalSettings?.deleteNotificationsReminderMeetingAfterCompleted,
  );
  const [isSyncOneSToggleEnabled, setIsSyncOneSToggleEnabled] = useState(false);
  const [shouldUpdatePreferences, setShouldUpdatePreferences] = useState(false);
  const [link, setLink] = useState("");
  const [isVisibleZoomSuccess, setIsVisibleZoomSuccess] = useState(false);

  const fetchedShowBusiness = useSelector(selectShowBusiness);
  const fetchedShowPersonal = useSelector(selectShowPersonal);
  const isLoadingUserSettings = useSelector(selectIsLoadingUserSettings);
  const settings = useSelector(selectUserPersonalSettings);
  const { directorId } = userSelector();
  const fetchedShowOneS = settings?.oneSTasksSync ?? false;

  const storedShowBusiness = useRef<string | null>(null);
  const storedShowPersonal = useRef<string | null>(null);

  const dispatch = useDispatch();

  const { data: outlookAuthData } = useGetOutlookAuthStatusQuery();
  const [revokeAuthOutlook] = useRevokeAuthOutlookMutation();

  const { data: zoomStatus, refetch } = useGetZoomAuthStatusQuery(undefined);
  const { data: zoomInfo, isFetching } = useGetZoomInfoQuery(undefined, { skip: zoomStatus !== "AUTHORIZED" });
  const [revokeAuthZoom] = useRevokeAuthZoomMutation();

  useEffect(() => {
    if (location.pathname === "/profile/zoom-success" && params.get("code")) {
      setIsVisibleZoomSuccess(true);
      userSettings
        .authZoom(params.get("code"))
        .then(refetch)
        .then(() => dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Users, id: "ZOOM-INFO" }])));
    }
  }, [location.pathname, params]);

  const handleToggle1Change = useCallback(() => {
    if (isToggle2Enabled) {
      setIsToggle1Enabled(!isToggle1Enabled);
      setShouldUpdatePreferences(true);
    }
    dispatch(api.util.invalidateTags([StoreTagTypes.Meet, StoreTagTypes.Calendar]));
  }, [isToggle1Enabled, isToggle2Enabled]);

  const handleToggle2Change = useCallback(() => {
    if (isToggle1Enabled) {
      dispatch(setIsHidePersonalTasks(isToggle2Enabled));
      setIsToggle2Enabled(!isToggle2Enabled);
      setShouldUpdatePreferences(true);
    }
    dispatch(api.util.invalidateTags([StoreTagTypes.Meet, StoreTagTypes.Calendar]));
  }, [isToggle1Enabled, isToggle2Enabled]);

  const handeleOneSToggle = () => {
    setIsSyncOneSToggleEnabled(!isSyncOneSToggleEnabled);
    setShouldUpdatePreferences(true);
  };

  const handleCancelNotifications = () => {
    setCancelNotificationsPastMeetings(!isCancelNotificationsPastMeetings);
    setShouldUpdatePreferences(true);
  };

  const getStoredUserPreferences = () => {
    storedShowBusiness.current = localStorage.getItem("showBusiness");
    storedShowPersonal.current = localStorage.getItem("showPersonal");

    if (storedShowBusiness.current !== null && storedShowPersonal.current !== null) {
      setIsToggle1Enabled(!storedShowBusiness.current);
      setIsToggle2Enabled(!storedShowPersonal.current);
    }
  };

  const updateDisplayPreferences = async () => {
    localStorage.setItem("showBusiness", JSON.stringify(isToggle1Enabled));
    localStorage.setItem("showPersonal", JSON.stringify(isToggle2Enabled));

    dispatch(
      updatePersonalSettings({
        id: directorId,
        body: {
          showBusinessTasks: isToggle1Enabled,
          showBusinessEvents: isToggle1Enabled,
          showPersonalTasks: isToggle2Enabled,
          showPersonalEvents: isToggle2Enabled,
          oneSTasksSync: isSyncOneSToggleEnabled,
          deleteNotificationsReminderMeetingAfterCompleted: isCancelNotificationsPastMeetings,
        },
      }),
    );

    setShouldUpdatePreferences(false);
  };

  useEffect(() => {
    getStoredUserPreferences();
    dispatch(getPersonalSettings({ id: directorId }));
  }, []);

  useEffect(() => {
    shouldUpdatePreferences && updateDisplayPreferences();
  }, [shouldUpdatePreferences]);

  useEffect(() => {
    setIsToggle1Enabled(Boolean(fetchedShowBusiness));
    localStorage.setItem("showBusiness", JSON.stringify(fetchedShowBusiness));
  }, [fetchedShowBusiness, storedShowBusiness.current]);

  useEffect(() => {
    setIsToggle2Enabled(Boolean(fetchedShowPersonal));
    localStorage.setItem("showPersonal", JSON.stringify(fetchedShowPersonal));
  }, [fetchedShowPersonal, storedShowPersonal.current]);

  useEffect(() => {
    setIsSyncOneSToggleEnabled(fetchedShowOneS);
  }, [fetchedShowOneS]);

  return (
    <div style={{ height: "100%" }}>
      <HeaderModal
        title="settings"
        leftSide={{ onPressClose: onClose }}
        styleContainer={isTablet && { backgroundColor: "#ECF0EF", paddingTop: 40 }}
      />
      <div style={styles.container}>
        <section style={{ display: "flex", marginBottom: 10, justifyContent: "center" }}>
          <button
            onClick={() => setActiveTab("meets")}
            style={{
              ...styles.btnTab,
              ...styles.btnTabLeftRadius,
              backgroundColor: activeTab === "meets" ? Colors.LIGHT.text.accent : "white",
            }}
          >
            <TextFont size={14} color={activeTab === "meets" ? "white" : Colors.LIGHT.text.accent} style={{ textAlign: "center" }}>
              <FormattedMessage id="Встречи" />
            </TextFont>
          </button>
          <button
            onClick={() => setActiveTab("integrations")}
            style={{
              ...styles.btnTab,
              ...styles.btnTabRightRadius,
              backgroundColor: activeTab === "integrations" ? Colors.LIGHT.text.accent : "white",
            }}
          >
            <TextFont size={14} color={activeTab === "integrations" ? "white" : Colors.LIGHT.text.accent} style={{ textAlign: "center" }}>
              <FormattedMessage id="integrations" />
            </TextFont>
          </button>
        </section>

        <div>
          {activeTab === "meets" && (
            <LoaderRenderWithCondition condition={isLoadingUserSettings}>
              <div>
                <TextFont size={18} style={styles.settingsText}>
                  <FormattedMessage id="settingsTaskMeetTitle" defaultMessage="Отображение встреч и задач" />
                </TextFont>
                <SettingsOptionItem
                  textId={"settingsBusinessMeetingsOption"}
                  defaultText="Отображать бизнес встречи и задачи"
                  isToggleEnabled={isToggle1Enabled}
                  onToggleChange={handleToggle1Change}
                  disableToggle={!isToggle2Enabled}
                />
                <SettingsOptionItem
                  textId={"settingsPersonalMeetingsOption"}
                  defaultText="Отображать личные встречи и задачи"
                  isToggleEnabled={isToggle2Enabled}
                  onToggleChange={handleToggle2Change}
                  disableToggle={!isToggle1Enabled}
                />
                <TextFont size={12} style={styles.thinText}>
                  <FormattedMessage
                    id="settingsTaskMeetDescription"
                    defaultMessage="При выключенном параметре соответствующие встречи и задачи не отображаются в списке задач и календарях"
                  />
                </TextFont>

                <SettingsOptionItem
                  textId={"deleteNotificationsPastMeetings"}
                  isToggleEnabled={personalSettings?.deleteNotificationsReminderMeetingAfterCompleted}
                  onToggleChange={handleCancelNotifications}
                  style={{ height: 56 }}
                  disableToggle={false}
                />
                <RemindMeetings
                  durations={personalSettings?.notificationDurations}
                  types={personalSettings?.notificationTypes}
                  currentId={user?.id}
                />
              </div>
            </LoaderRenderWithCondition>
          )}

          {activeTab === "integrations" && (
            <>
              <LinkToZoom setLink={setLink} link={link} />
              <section style={styles.wrapperIntegration}>
                <div style={styles.wrapperIntegrationBlock}>
                  <SvgOutlook />
                  <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                      <TextFont size={18}>Outlook</TextFont>
                      {/* TempHide for v1.22.2 */}
                      {/* <SvgQuestion fill={Colors.LIGHT.background.grey} /> */}
                    </div>
                    {outlookAuthData?.status === "AUTHORIZED" ? (
                      <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                        <TextFont size={14} weight="700">
                          <FormattedMessage id="synchronized" />
                        </TextFont>
                        <button onClick={revokeAuthOutlook}>
                          <TextFont size={16} color={Colors.LIGHT.red}>
                            <FormattedMessage id="unlink" />
                          </TextFont>
                        </button>
                      </div>
                    ) : (
                      <button style={styles.btnLinking} onClick={() => setIsVisibleOutlock(true)}>
                        <TextFont size={16} style={{ textAlign: "center" }}>
                          <FormattedMessage id="createLinkOutlook" />
                        </TextFont>
                      </button>
                    )}
                  </div>
                </div>
                <div style={styles.wrapperIntegrationBlock}>
                  <Svg1C />
                  <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                      <TextFont size={18}>1С:Документооборот</TextFont>
                      {/* TempHide for v1.22.2 */}
                      {/* <SvgQuestion fill={Colors.LIGHT.background.grey} /> */}
                    </div>
                    {isSyncOneSToggleEnabled ? (
                      <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                        <TextFont size={14} weight="700">
                          <FormattedMessage id="synchronized" />
                        </TextFont>
                        <button onClick={handeleOneSToggle}>
                          <TextFont size={16} color={Colors.LIGHT.red}>
                            <FormattedMessage id="unlink" />
                          </TextFont>
                        </button>
                      </div>
                    ) : (
                      <button style={styles.btnLinking} onClick={handeleOneSToggle}>
                        <TextFont size={16} style={{ textAlign: "center" }}>
                          <FormattedMessage id="createLink" />
                        </TextFont>
                      </button>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </div>
      {isVisibleOutlock && <AuthOutlook onClose={() => setIsVisibleOutlock(false)} />}

      <ZoomSuccess isVisible={isVisibleZoomSuccess} onClose={() => setIsVisibleZoomSuccess(false)} />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    height: "85%",
    "overflow-y": "auto",
    marginTop: 20,
    paddingLeft: 12,
    paddingRight: 12,
    gap: 30,
  },
  settingsText: {
    color: Colors.LIGHT.text.darkGreen,
    fontWeight: "700",
  },
  thinText: {
    fontWeight: "400",
    paddingTop: 10,
    color: Colors.LIGHT.text.darkGreen,
  },
  btnTab: {
    maxWidth: 350,
    width: "50%",
    paddingTop: 8,
    paddingBottom: 8,
    border: `1px solid ${Colors.LIGHT.text.accent}`,
  },
  btnTabLeftRadius: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  btnTabRightRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  btnLinking: {
    border: `1px solid ${Colors.LIGHT.background.grey}`,
    borderRadius: 8,
    padding: "4px 8px",
    alignSelf: "flex-start",
  },
  wrapperIntegration: {
    display: "flex",
    "flex-direction": "column",
    marginTop: 30,
    gap: 24,
  },
  wrapperIntegrationBlock: {
    display: "flex",
    gap: 16,
    justifyContent: "flex-start",
  },
};

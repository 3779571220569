import React, { FC, useEffect } from "react";

import styled from "styled-components";

import { AttachFileContextMenuButton } from "@shared/fileAttachments";
import { Colors } from "@theme/colors";

interface IProps {
  componentMeetingTasks: JSX.Element;
  close: () => void;
  openFilePicker: ({ closeOverModal }: { closeOverModal?: () => void }) => void;
}

export const Menu: FC<IProps> = ({ componentMeetingTasks, close, openFilePicker }) => {
  useEffect(() => {
    document.getElementById("modalMeet").addEventListener("click", (e) => {
      if (!document.getElementById("meetActions1")?.contains(e?.target)) {
        close();
      }
    });
  }, []);

  return (
    <ContentWrap id="meetActions1">
      {componentMeetingTasks}
      <AttachFileContextMenuButton openFilePicker={() => openFilePicker({ closeOverModal: close })} />
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  position: absolute;
  top: 100px;
  right: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 6px ${Colors.LIGHT.shadowTooltip};
  background-color: ${Colors.LIGHT.white};
  z-index: 999;
`;

import React, { FC, useCallback, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as CancelIcon } from "@assets/cancel_small.svg";
import { ReactComponent as CompleteIcon } from "@assets/complete.svg";
import { ReactComponent as TableIcon } from "@assets/table.svg";
import { ReactComponent as TvIcon } from "@assets/tv_stand.svg";
import { ReactComponent as UsersIcon } from "@assets/users.svg";
import { RenderWithCondition } from "@hoc";
import { IMeetingRoom, IOccupancyRoom } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { HEIGHT_BLOCK } from "../../../activityParticipants/components/Activity/constants";
import { IMeetingRoomWithColor } from "../MeetingRoomsBooking";

interface IProps {
  data: IMeetingRoomWithColor[];
  occupancyRooms: IOccupancyRoom[];
  handleSelectedRoom: (room: IMeetingRoom) => void;
  selectedRoom: IMeetingRoom | null;
}

export const MeetingRooms: FC<IProps> = ({ data, occupancyRooms, handleSelectedRoom, selectedRoom }) => {
  function getOccupancyRoomId(id: string) {
    const currentRoom = occupancyRooms?.filter((room) => id === room.externalId)[0];
    return !currentRoom?.meetings.length;
  }

  function getInitials(str: string) {
    if (!str) return "";
    const firstCharts = str
      .replace(/[№,"]/g, "")
      .split(" ")
      .map((value) => value.slice(0, 1))
      .join("");
    return firstCharts.slice(0, 2).toLocaleUpperCase();
  }

  return (
    <>
      <ContentWrap>
        <HeaderBlock>
          <TextFont size={18} weight="700">
            <FormattedMessage id="chooseMeetingRoom" defaultMessage={"Выберите подходящую переговорную"} />
          </TextFont>
          <TextFont size={16} lineHeight={24} color={Colors.LIGHT.text.main}>
            <FormattedMessage id="pushForChooseMeetingRoom" defaultMessage={"Для выбора нажмите на подходящую переговорную"} />
          </TextFont>
        </HeaderBlock>
        <MeetingRoomsList>
          {data.map((meetingRoom, index) => (
            <MeetingRoomItem
              heightValue={HEIGHT_BLOCK}
              key={meetingRoom.id}
              onClick={() => handleSelectedRoom(meetingRoom)}
              active={meetingRoom.id === selectedRoom?.id}
            >
              <Avatar backgroundColor={meetingRoom.colorAvatar}>
                <AbsoluteNum>
                  <TextFont size={10} lineHeight={10} color={Colors.LIGHT.placeholder.main} weight="700">
                    {index + 1}.
                  </TextFont>
                </AbsoluteNum>
                <TextFont size={14} lineHeight={14} color={Colors.LIGHT.white} weight="700">
                  {getInitials(meetingRoom.name)}
                </TextFont>
              </Avatar>
              <InfoBlock>
                <TextFont size={16} lineHeight={20} color={Colors.LIGHT.text.main} weight="700">
                  {meetingRoom.name}
                </TextFont>
                <InfoLine>
                  <InfoItem>
                    <InfoIcon>
                      <RenderWithCondition condition={getOccupancyRoomId(meetingRoom.externalId)}>
                        <CompleteIcon />
                      </RenderWithCondition>
                      <RenderWithCondition condition={!getOccupancyRoomId(meetingRoom.externalId)}>
                        <CancelIcon />
                      </RenderWithCondition>

                      <TextFont
                        size={12}
                        lineHeight={16}
                        color={getOccupancyRoomId(meetingRoom.externalId) ? Colors.LIGHT.green : Colors.LIGHT.red}
                      >
                        <FormattedMessage
                          id={getOccupancyRoomId(meetingRoom.externalId) ? "available" : "occupied"}
                          defaultMessage={getOccupancyRoomId(meetingRoom.externalId) ? "Свободно" : "Занято"}
                        />
                      </TextFont>
                    </InfoIcon>
                    <InfoIcon>
                      <UsersIcon />
                      <TextFont size={12} lineHeight={16} color={Colors.LIGHT.placeholder.main}>
                        34
                      </TextFont>
                    </InfoIcon>
                    <InfoIcon>
                      <TvIcon />
                    </InfoIcon>
                    <InfoIcon>
                      <TableIcon />
                    </InfoIcon>
                  </InfoItem>
                </InfoLine>
              </InfoBlock>
            </MeetingRoomItem>
          ))}
        </MeetingRoomsList>
      </ContentWrap>
    </>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 58px;
  margin: 30px 0;
  padding-left: 20px;
  cursor: pointer;
`;
const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const MeetingRoomsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MeetingRoomItem = styled.div<{ heightValue: number; active: boolean }>`
  display: flex;
  border: 1px solid transparent;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
  background-color: ${Colors.LIGHT.white};
  align-items: center;
  padding: 0px 10px;
  gap: 14px;
  border-radius: 7px;
  transition: all 0.5s ease;
  ${(props) => props.heightValue && `height: ${props.heightValue}px;`}
  &:hover {
    background-color: ${Colors.LIGHT.hover};
    transition: all 0.3s ease;
  }
  &:active {
    background-color: ${Colors.LIGHT.green100};
  }
  ${(props) =>
    props.active &&
    `
    background-color: ${Colors.LIGHT.green400};
    border: 1px solid  ${Colors.LIGHT.green700};
    &:hover {
    background-color: ${Colors.LIGHT.green400};
    transition: all 0.3s ease;
  }
   }
  `}
`;
const Avatar = styled.div<{ backgroundColor: string }>`
  display: flex;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
const AbsoluteNum = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: -7px;
`;
const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const InfoLine = styled.div`
  display: flex;
  gap: 8px;
`;
const InfoItem = styled.div`
  display: flex;
  gap: 8px;
`;
const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

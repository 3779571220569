import React, { memo, useMemo, useEffect, useState, useCallback, useRef } from "react";

import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { LoaderTypes } from "@components/hoc/LoaderRenderWithCondition";
import { LoaderRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { TTaskSubType } from "@interfaces/businessTask.interface";
import { useFileAttachments } from "@shared/fileAttachments";
import {
  addSelectedItemId,
  addSelectedMeetings,
  addVerboseTasks,
  resetAttachedEventsStore,
  resetAttachedTasks,
  resetSelectedItemIds,
} from "@store/attachedMeetingTasks/slice";
import { get, selectBusinessTasks, updateTaskId } from "@store/businessTask";
import { TaskSubtypes } from "@store/businessTask";
import { saveModal } from "@store/configure";
import { selectTheme } from "@store/theme";
import { ItemContentLoader, ModalUI } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";

import { ViewNotSave } from "./components/ViewNotSave/ViewNotSave";
import { ViewSave } from "./components/viewSave/ViewSave";
import "./components/styles.css";

interface IModal {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  id: string;
  data?: Record<keyof ITask, ITask[keyof ITask]>;
  isModal?: boolean;
  taskSubType?: TTaskSubType;
  outModalClose?: () => void;
  disablePersonalTasks?: boolean;
}

export const Modal = memo(
  ({
    isVisible,
    taskSubType = TaskSubtypes.Business,
    setIsVisible,
    data,
    isModal = true,
    id,
    outModalClose,
    disablePersonalTasks = false,
  }: IModal) => {
    const dispatch = useDispatch();

    const { currentTask, isLoadingGetTask } = useSelector(selectBusinessTasks);

    // TODO: ======== TEMPORARY MOCKdata =========
    // const {  isLoadingGetTask } = useSelector(selectBusinessTasks);
    // const currentTask = oneSTaskMock;

    const theme = selectTheme("extra");

    const [isSave, setIsSave] = useState(!!id);
    const [isEdit, setIsEdit] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const isPersonalTask = useMemo(() => id && taskSubType === TaskSubtypes.Personal, [id, taskSubType]);
    const files = useMemo(() => (currentTask ? currentTask.files ?? [] : []), [currentTask]);

    const containerRef = useRef<HTMLDivElement>(null);

    const {
      allFiles,
      uploadedFiles,
      deleteFile,
      handleFileDownload,
      resetUploadedFiles,
      openFilePicker,
      removeUploadedFilesFromServer,
      FileInputField,
      resetFileIdsToRemove,
      restoreSoftDeletedFiles,
    } = useFileAttachments({ initialFiles: files });

    const getTask = useCallback(async () => {
      if (id) {
        dispatch(resetAttachedTasks());
        dispatch(resetSelectedItemIds());

        const response = await dispatch(get({ id, isPersonalTask }));
        const data = response?.payload?.response?.data;

        data && dispatch(addVerboseTasks(data));

        if (data?.attachedMeetings) {
          dispatch(addSelectedMeetings(data.attachedMeetings));
          for (const item of data.attachedMeetings) {
            dispatch(addSelectedItemId(item.id));
          }
        }
      }
    }, [id, isPersonalTask]);

    const handleClose = () => {
      setIsVisible(false);
    };

    const handleCancel = () => {
      setIsCancel(false);

      resetFileIdsToRemove();
      restoreSoftDeletedFiles();
      removeUploadedFilesFromServer({ removeUploaded: true, removeMarkedToDelete: false });

      if (currentTask) {
        setTimeout(() => setIsSave(true), 100);
      } else {
        handleClose();
      }
    };

    const onBack = () => {
      if (isSave) {
        return handleClose();
      }

      if (isEdit) {
        return setIsCancel(true);
      }
      return handleClose();
    };

    const msgCancelExeption = useIntl().formatMessage({ id: "cancelException" });

    useEffect(() => {
      if (currentTask && !id) {
        dispatch(updateTaskId(""));
      }

      if (id !== currentTask?.id) {
        getTask();
      }
    }, [id]);

    useEffect(() => {
      if (!isModal) {
        dispatch(saveModal(true));
      }
      return () => {
        dispatch(updateTaskId(""));
        if (!isModal) {
          dispatch(saveModal(false));
        }
      };
    }, []);

    useEffect(
      () => () => {
        dispatch(resetAttachedEventsStore());
      },
      [],
    );

    const renderView =
      isSave && currentTask ? (
        <ViewSave
          handleClose={handleClose}
          setIsSave={setIsSave}
          task={currentTask}
          outModalClose={outModalClose}
          isModal={isModal}
          isPersonalTask={isPersonalTask}
          allFiles={allFiles}
          uploadedFiles={uploadedFiles}
          deleteFile={deleteFile}
          handleFileDownload={handleFileDownload}
          resetUploadedFiles={resetUploadedFiles}
          openFilePicker={openFilePicker}
          isFromOneS={currentTask?.isFromOneS ?? false}
        />
      ) : (
        <ViewNotSave
          task={currentTask}
          joinData={data}
          isCancel={isCancel}
          setIsCancel={setIsCancel}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          handleClose={handleClose}
          setIsSave={setIsSave}
          isPersonalTask={isPersonalTask}
          disablePersonalTasks={disablePersonalTasks}
          allFiles={allFiles}
          uploadedFiles={uploadedFiles}
          deleteFile={deleteFile}
          handleFileDownload={handleFileDownload}
          openFilePicker={openFilePicker}
          removeUploadedFilesFromServer={removeUploadedFilesFromServer}
        />
      );

    return (
      <>
        {isModal ? (
          <ModalUI
            bg={theme.background.main}
            onShow={() => dispatch(updateTaskId(true))}
            id="modalBusinessTask"
            onClose={onBack}
            isVisible={isVisible}
            scrollEnable={false}
          >
            <ContentModal ref={containerRef}>
              <LoaderRenderWithCondition condition={isLoadingGetTask} loaderComponent={<ItemContentLoader width={"99%"} height={"100%"} />}>
                {renderView}
              </LoaderRenderWithCondition>
            </ContentModal>
          </ModalUI>
        ) : (
          <Content id="modalBusinessTask">
            <LoaderRenderWithCondition condition={isLoadingGetTask} loaderType={LoaderTypes.ItemContent} parent={containerRef.current}>
              {renderView}
            </LoaderRenderWithCondition>
          </Content>
        )}
        <RenderWithCondition condition={isCancel}>
          <NotificationConfirm phraseId={msgCancelExeption} onOk={handleCancel} onCancel={() => setIsCancel(false)} />
        </RenderWithCondition>
        <FileInputField />
      </>
    );
  },
);

const ContentModal = styled.div`
  height: 100%;
  width: 100%;
`;
const Content = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.extra.background.main};
`;

import React, { FC, useMemo } from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { Colors } from "@theme/colors";
import { isTablet } from "@utils/index";

import { BORDER_WIDTH, HEIGHT_BLOCK, WIDTH_BLOCK } from "../constants";

interface IProps {
  events: Partial<IEventsOfDay>[];
}

export const RowParticipant: FC<IProps> = ({ events }) => (
  <ContentWrap>
    {events.map((event) => (
      <Event key={event.id} event={event} />
    ))}
  </ContentWrap>
);

const Event = ({ event }: { event: Partial<IEventsOfDay> }) => {
  const params = useMemo(() => {
    const widthMinute = WIDTH_BLOCK / 60;
    const width = widthMinute * dayjs(event.endTime).diff(event.startTime, "minutes");
    const left = dayjs(event.startTime).hour() * WIDTH_BLOCK + widthMinute * dayjs(event.startTime).minute();
    const color = Colors.LIGHT.green500;

    return { width, left, color };
  }, [event.startTime, event.endTime, event.type]);

  return <EventItem isTablet={isTablet} left={params.left} width={params.width} backColor={params.color} />;
};

const ContentWrap = styled.div`
  display: flex;
  height: ${HEIGHT_BLOCK + BORDER_WIDTH}px;
  width: 100%;
  position: relative;
`;
const EventItem = styled.div<{ isTablet: boolean; left: number; width: number; backColor: string }>`
  bottom: 0;
  border-radius: 4px;
  position: absolute;
  top: ${({ isTablet }) => (isTablet ? "8px" : "0")};
  ${({ left, width, backColor }) => `
  left: ${left}px;
  width: ${width}px;
  background-color: ${backColor}; 
`}
`;

import React, { HTMLAttributes, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import { selectBusinessTasks } from "@store/businessTask";
import { selectConfigure } from "@store/configure";
import { selectScreenDay } from "@store/screenDay";
import { selectTheme, themeSelector } from "@store/theme";
import { Colors } from "@theme/colors";

type TFont = "bold" | "regular" | "italic" | "thin";

type TProps = HTMLAttributes<HTMLSpanElement> & {
  className?: string;
  type?: TFont;
  size?: number | string;
  align?: "start" | "end" | "left" | "right" | "center";
  weight?: "700" | "400" | "500";
  color?: string;
  lineHeight?: number;
};

export const TextFont = ({ type = "regular", weight, size, lineHeight, color, align, ...props }: TProps) => {
  const screen = window.location.pathname;
  const theme = selectTheme("extra");

  const { isOpenModalTask } = useSelector(selectBusinessTasks);
  const { isOpenModalMeet } = useSelector(selectScreenDay);
  const { activeModal } = useSelector(selectConfigure);
  const { extra } = themeSelector();

  const styleFont = useMemo(() => {
    switch (type) {
      case "bold": {
        return "Roboto-Bold";
      }
      case "italic": {
        return "Roboto-Italic";
      }
      case "regular": {
        return "Roboto-Regular";
      }
      case "thin": {
        return "Roboto-Thin";
      }
      default: {
        return "Roboto-Regular";
      }
    }
  }, [type]);

  const defaultColor = useMemo(() => {
    if ((screen === "/" || screen === "/tasks") && (isOpenModalTask || isOpenModalMeet) && extra === "ASSISTANT" && activeModal === 1) {
      return theme.text.main;
    }
    return Colors.LIGHT.text.main;
  }, [activeModal, extra, isOpenModalMeet, isOpenModalTask, screen, theme.text.main]);

  return (
    <div
      className={props.className}
      {...props}
      style={{
        color: color ?? defaultColor,
        fontSize: size ?? 14,
        fontWeight: weight,
        textAlign: align ?? "left",
        fontFamily: `${styleFont}, sans-serif`,
        wordBreak: "break-word",
        whiteSpace: "pre-line",
        ...(lineHeight && { lineHeight: `${lineHeight}px` }),
        ...props.style,
      }}
    />
  );
};

import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Colors } from "@theme/colors";
import { ModalUI, HeaderModal, TextFont } from "@ui";

interface IProps {
  close: () => void;
  onSave: (comment: string) => void;
  title?: string;
}

export const ModalComment: FC<IProps> = ({ close, onSave, title = "" }) => {
  const [comment, setComment] = useState("");

  return (
    <ModalUI isVisible={true} onClose={close}>
      <div>
        <HeaderModal
          title={title}
          leftSide={{ onPressClose: close, isHideCancel: true }}
          isEdit={true}
          rightSide={{ onPress: () => onSave(comment) }}
        />
        <Content>
          <TextareaComment value={comment} onChange={(e) => setComment(e.target.value)} placeholder={"Комментарий"} maxLength={100} />
          <ButtonSave onClick={() => onSave(comment)}>
            <TextFont size={18} weight="700" color={Colors.LIGHT.white} align="center">
              <FormattedMessage id={"save"} />
            </TextFont>
          </ButtonSave>
        </Content>
      </div>
    </ModalUI>
  );
};

const Content = styled.div`
  padding: 20px 12px 0 12px;
`;
const TextareaComment = styled.textarea`
  font-size: 18px;
  border-radius: 10px;
  color: ${Colors.LIGHT.text.main};
  border: 1px solid ${Colors.LIGHT.white};
  background-color: ${Colors.LIGHT.white};
  margin-bottom: 20px;
  padding: 16px 20px;
  width: 90%;
  min-height: 130px;
`;
const ButtonSave = styled.button`
  width: 100%;
  padding: 16px 0;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: ${Colors.LIGHT.text.accent};
`;

import styled from "styled-components";

import { TextFont } from "@components/ui";
import { Colors } from "@theme/colors";

import { ReactComponent as SvgOwner } from "../../../participants/assets/owner.svg";
import { HEIGHT_BLOCK } from "../Activity/constants";

export const ContentWrap = styled.div`
  margin-top: 30px;
`;
export const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ListWrap = styled.div`
  margin-top: 15px;
  gap: 8px;
`;
export const ButtonItem = styled.button<{ isNotPointer: boolean; isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  width: 96%;
  height: ${HEIGHT_BLOCK}px;
  padding-top: 0;
  padding-bottom: 0;
  ${({ isNotPointer }) => isNotPointer && "cursor: default"};
  box-sizing: content-box;
  border-bottom: 0.5px solid ${Colors.LIGHT.lighGrey};
  ${({ isActive }) => isActive && `border:1px solid ${Colors.LIGHT.background.grey}`};
`;

export const ContentItem = styled.div`
  display: flex;
  flex: 8;
  height: 100%;
  width: 95%;
`;
export const BlockAvatar = styled.div`
  display: flex;
`;
export const AvatarWrap = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
export const BlockText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;
export const TextOrdinal = styled(TextFont)`
  align-self: flex-end;
  width: 9px;
  word-break: normal;
`;
export const SvgOwnerStyled = styled(SvgOwner)`
  position: absolute;
  right: 5px;
  bottom: 0;
`;

export const TextUserName = styled(TextFont)<{ isLineThrough: boolean }>`
  margin-bottom: 0;
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  ${({ isLineThrough }) => isLineThrough && "text-decoration: line-through;"}
`;

export const TextWorkPosition = styled(TextFont)`
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;
export const ButtonDelete = styled.button`
  height: ${HEIGHT_BLOCK}px;
  align-items: center;
  padding-right: 8px;
  padding-top: 8px;
`;

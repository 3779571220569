import React, { FC, Dispatch, SetStateAction, useMemo, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser, TCreateExternalUser } from "@services/meet.service";
import { createExternalUser, selectExternalUsers } from "@store/externalUsers";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, TextFont } from "@ui";

interface IProps {
  isVisible: boolean;
  participantEmail: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  closeParticipant: () => void;
  handleAddUser?: (user: IStateUser | IExternalUser, isExtenral?: boolean) => void;
}

export const InviteExternalParticipantBottomSheet: FC<IProps> = ({
  isVisible,
  participantEmail,
  setSearch,
  setIsVisible,
  closeParticipant,
  handleAddUser,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { isLoading } = useSelector(selectExternalUsers);

  const dispatch = useDispatch();

  const isEdit = useMemo(() => firstName.length && lastName.length, [firstName, lastName]);

  const placeholderMeetName = useIntl().formatMessage({ id: "firstName" });
  const placeholderMeetDescription = useIntl().formatMessage({ id: "lastName" });

  const handlePressSave = async () => {
    if (!participantEmail || !firstName || !lastName) return;

    const body: TCreateExternalUser = {
      email: participantEmail,
      firstName,
      lastName,
      deleted: false,
    };

    const response = await dispatch(createExternalUser(body));

    handleAddUser?.(response.payload, true);
    setSearch && setSearch("");
    setIsVisible(false);
  };

  return (
    <ModalUI isVisible={isVisible} backdropOpacity onBackdropPress={() => setIsVisible(false)} onClose={() => setIsVisible(false)}>
      <HeaderModal title=" " leftSide={{ onPressClose: () => setIsVisible(false), isHideCancel: true }} />

      <ContentWrap>
        <Content>
          <InputName
            marginTop={20}
            placeholder={placeholderMeetName}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoFocus={true}
            maxLength={100}
          />
          <InputName
            marginTop={16}
            placeholder={placeholderMeetDescription}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            maxLength={100}
          />

          <ButtonSave disabled={!isEdit} isEdit={!!isEdit} onClick={handlePressSave}>
            <LoaderButtonRenderWithCondition condition={isLoading}>
              <TextButton size={18} color={Colors.LIGHT.white} weight="700">
                <FormattedMessage id="add" />
              </TextButton>
            </LoaderButtonRenderWithCondition>
          </ButtonSave>
        </Content>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  padding: 0 17px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputName = styled.input<{ marginTop: number }>`
  border-radius: 9px;
  padding: 16px;
  border: 1px solid ${Colors.LIGHT.grey};
  font-size: 18px;
  margin-top: ${(props) => props.marginTop}px;
`;
const ButtonSave = styled.button<{ isEdit: boolean }>`
  display: flex;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  margin-top: 15px;
  background-color: ${(props) => (props.isEdit ? Colors.LIGHT.background.green : Colors.LIGHT.lighGrey)};
`;
const TextButton = styled(TextFont)`
  margin: 12px;
  color: ${Colors.LIGHT.red};
`;

import React, { FC, useState } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { ButtonBillet } from "@components/screens/day/components/timetable/main/modal/components/placement/placement.item/styled";
import { RenderWithCondition } from "@hoc";
import { selectSpecifyGoal } from "@store/businessGoals";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";

import { CommonFields } from "../types";

import { ModalGoal } from "./ModalGoal";

interface IProps extends CommonFields {
  id: string;
}

export const Goal: FC<IProps> = ({ id, handleData }) => {
  const [isVisibleGoal, setIsVisibleGoal] = useState(false);

  const currentGoal = useSelector(selectSpecifyGoal(id));

  return (
    <ContentWrap>
      <TextFont type="bold" size={18} weight="700">
        Цель к задаче
      </TextFont>
      <ButtonBillet onClick={() => setIsVisibleGoal(true)}>
        <TextSelected size={16}>{currentGoal ? currentGoal.description : "Ничего не выбрано"}</TextSelected>
        <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
      </ButtonBillet>

      <RenderWithCondition condition={isVisibleGoal}>
        <ModalGoal isVisible={isVisibleGoal} goalId={id} setGoal={handleData} close={() => setIsVisibleGoal(false)} />
      </RenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.section`
  display: flex;
  flex-direction: column;
`;

const TextSelected = styled(TextFont)`
  width: 90%;
`;

import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { LineHorizontal } from "@components/shared";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI } from "@ui";

import { RoundButton } from "../../../../viewSave/modalDelete/ModalDelete";
import { TypeRepeat, tempMeetRepeat, titleType } from "../../../constants";

interface IProps {
  close: () => void;
  type: TypeRepeat;
  reset: () => void;
  changeMeet: (value: any) => void;
}

export const RepeatType: FC<IProps> = ({ close, changeMeet, type, reset }) => {
  const handleSelect = (value: TypeRepeat) => {
    changeMeet({ ...tempMeetRepeat, type: value });

    if (value === "NOT_REPEAT") {
      reset();
    } else {
      close();
    }
  };

  return (
    <ModalUI isVisible={true} className="bottomModal" bg={Colors.LIGHT.white} onClose={close}>
      <div>
        <RoundButton onClick={close}>
          <SvgClose />
        </RoundButton>
        <BlockButtons>
          {Object.keys(titleType).map((element) => (
            <ButtonWrap key={element} isElement={type === element}>
              <ButtonItem onClick={() => handleSelect(element)}>
                <TextFont>
                  <FormattedMessage id={titleType[element]} />
                </TextFont>
              </ButtonItem>
              <LineHorizontal />
            </ButtonWrap>
          ))}
        </BlockButtons>
      </div>
    </ModalUI>
  );
};

const BlockButtons = styled.div`
  margin-top: 50px;
`;
const ButtonItem = styled.button`
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding: 0 12px;
  width: 100%;
`;
const ButtonWrap = styled.div<{ isElement: boolean }>`
  background-color: ${(props) => (props.isElement ? `${Colors.LIGHT.disabled}` : `${Colors.LIGHT.white}`)};
`;

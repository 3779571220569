import React, { memo, useEffect, useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgDeadline } from "@assets/deadline.svg";
import { ReactComponent as SvgDone } from "@assets/done_circle.svg";
import { ReactComponent as SvgReturnWork } from "@assets/return_work.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IExtendedComment, ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { IFile } from "@interfaces/files.interfaces";
import { IColors } from "@interfaces/theme.interface";
import { useWorkDaysController } from "@screens/day/components/ListTasks/Modal/components/addWorkDaysReminder";
import { TaskMeetings } from "@screens/day/components/ListTasks/Modal/components/taskMeetings/TaskMeeting";
import { OneSTaskStatusHeader } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/OneSTaskStatusHeader";
import { Resolution1CActions } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/resolutionOneSActionButtons/Resolutions1CActions";
import { PlanningWork, FileAttachments } from "@shared";
import { EventComments } from "@shared";
import { EventCommentsTypes } from "@shared/eventComments/EventComments";
import { removeFilesFromServer } from "@shared/fileAttachments/utils";
import { complete, reopen, selectBusinessTasks, update, takeMe } from "@store/businessTask";
import { selectTheme } from "@store/theme";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { HeaderModal, TextFont } from "@ui";
import { showToast, toFormatDayMonth } from "@utils";
import { myTracker } from "@utils/myTracker";
import { shouldShowWorkdayReminder } from "@utils/shouldShowWorkdayReminder";
import { intl } from "@utils/translate";

import { Item } from "../../../taskItem/Item";
import { Actions, tempWorkDay } from "../../index";
import { Modal as ModalTask } from "../../Modal";
import { Delegate } from "../delegate/Delegate";

import { Description, HeaderTablet, ViewSaveStatus } from "./components";
import { OneSResolutions } from "./components/resolutionOneSActionButtons/constants";
import { parseOneSAdditionalDescription } from "./utils";

interface IViewSave {
  task: ITask;
  handleClose: () => void;
  setIsSave: (arg: boolean) => void;
  isModal: boolean;
  outModalClose?: () => void;
  isPersonalTask: boolean;
  isFromOneS?: boolean;
  openFilePicker: () => void;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  resetUploadedFiles: () => void;
}

export const ViewSave = memo(
  ({
    task,
    isPersonalTask,
    isFromOneS,
    handleClose,
    setIsSave,
    isModal,
    outModalClose,
    openFilePicker,
    uploadedFiles,
    allFiles,
    deleteFile,
    handleFileDownload,
    resetUploadedFiles,
  }: IViewSave) => {
    const dispatch = useDispatch();

    const [isControlTaskDetailedScreenVisible, setIsControlTaskDetailedScreenVisible] = useState(false);
    const [isVisibleAddDay, setIsVisibleAddDay] = useState(false);
    const [isVisibleActions, setIsVisibleActions] = useState(false);
    const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
    const [isAddWorkDayReminderVisible, setIsWorkDayReminderVisible] = useState(false);

    const linkedTask = useMemo(() => task?.controlledTask || task?.controlTasks?.[0], [task]);
    const isDelegateBtnVisible = useMemo(() => task.delegatedFromUser || (task.delegatedToUser && !task.controlledTaskId), [task]);
    const controlledTask = task?.controlledTask;
    const modalRightBtnTitle = isSaveBtnVisible ? "save" : "change";
    const { currentUser } = userSelector();
    const { isLoadingTask } = useSelector(selectBusinessTasks);
    const theme = selectTheme("extra");

    const canEdit = useMemo(() => task.creator === currentUser?.id || task.delegatedFromUser || task.assistant, [currentUser, task]);

    const taskDescription = useMemo(
      () => (task.isFromOneS ? parseOneSAdditionalDescription(task.oneSTaskAdditionalDescription ?? "") : task.description),
      [task],
    );

    const commentsData = useMemo(() => {
      if (!isPersonalTask) {
        return task?.taskComments ?? [];
      }

      const extendedComments = [] as IExtendedComment[];
      const comments = (task?.comments && task.comments.slice(0)) ?? [];

      const userInfo = {
        id: currentUser?.id ?? "",
        firstName: currentUser?.firstName ?? "",
        lastName: currentUser?.lastName ?? "",
      };

      for (const comment of comments) {
        const extendedComment: IExtendedComment = {
          ...comment,
          user: userInfo,
        };

        extendedComments.push(extendedComment);
      }

      return extendedComments;
    }, [task, currentUser, isPersonalTask]);

    const handleTakeBack = useCallback(async () => {
      await dispatch(takeMe(task.id));

      if (shouldShowWorkdayReminder(task.workDays)) {
        setIsWorkDayReminderVisible(true);
      }
    }, [task]);

    const [WorkDaysModals, WorkDaysComponent] = useWorkDaysController({
      task,
      isPersonalTask,
      isAddWorkDayReminderVisible,
      setIsWorkDayReminderVisible,
      handleClose,
      outModalClose,
      allowWorkdaysAddition: canEdit,
    });

    const handleComplete = () => {
      myTracker("ReadyClickOnTask");
      dispatch(complete({ id: task.id, isPersonalTask }));
    };

    const handleReturnToWork = () => {
      dispatch(reopen({ id: task.id, isPersonalTask }));
      setIsWorkDayReminderVisible(true);
    };

    const handleSaveAddDays = (days: WorkDay[], timeEnabled?: boolean) => {
      dispatch(update({ id: task.id, isPersonalTask, data: { workDays: days, timeEnabled } }));
    };

    const onPressDisable = useCallback(() => {
      showToast("actionUnavailableCompletedTask");
    }, []);

    const isEdit = useMemo(
      () => task.status !== "COMPLETED" && task.status !== "CLOSED" && task.status !== "PAUSED" && canEdit,
      [task.status, canEdit],
    );

    const generateDate = useMemo(() => {
      if (task.isFromOneS) {
        if (task.plannedEndDate) {
          return `${toFormatDayMonth(dayjs(task.plannedEndDate))}`;
        }

        if (task.workDays) {
          const workDay = task.workDays?.length && task.workDays[task.workDays.length - 1].date;
          return `${toFormatDayMonth(dayjs(workDay))}`;
        }
      }

      if (task.plannedEndDate && task.startDate) {
        return `${toFormatDayMonth(dayjs(task.startDate))} - ${toFormatDayMonth(dayjs(task.plannedEndDate))}`;
      } else if (task.startDate) {
        return toFormatDayMonth(dayjs(task.startDate));
      } else if (task.plannedEndDate) {
        const isCurrentYear = dayjs(task.plannedEndDate).isSame(dayjs(), "year");
        return dayjs(task.plannedEndDate).format(isCurrentYear ? "DD.MM" : "DD.MM.YY");
      } else if (task.createdDate) {
        const isCurrentYear = dayjs(task.createdDate).isSame(dayjs(), "year");
        return `${intl.getMessage("created")} ${dayjs(task.createdDate).format(isCurrentYear ? "DD.MM" : "DD.MM.YY")}`;
      }
    }, [task.startDate, task.plannedEndDate, task.createdDate]);

    const onSaveHandler = useCallback(() => {
      (async () => {
        if (!isSaveBtnVisible) {
          setIsSave(false);
          return;
        }

        const updation = { fileIds: [...uploadedFiles.map((file) => file.id), ...(task.files?.map((item) => item.id) ?? [])] };

        dispatch(update({ id: task.id, isPersonalTask, data: updation }));

        resetUploadedFiles();
        setIsSaveBtnVisible(false);
      })();
    }, [uploadedFiles, isSaveBtnVisible]);

    const handleCloseWrapper = useCallback(() => {
      uploadedFiles.length && removeFilesFromServer(uploadedFiles.map((file) => file.id));
      resetUploadedFiles();
      handleClose();
    }, [uploadedFiles]);

    const openFilePickerWrapper = () => {
      setIsVisibleActions(false);
      openFilePicker();
    };

    const stylesWrapperStatus = useMemo(
      () => (task.status === "CLOSED" && !!task?.closeReason && !!task?.closeComment) || (task.status === "PAUSED" && !!task.pauseComment),
      [task?.closeComment, task?.closeReason, task.pauseComment, task.status],
    );

    const isRenderStatus =
      task.status === "CLOSED" || task.status === "COMPLETED" || task.status === "PAUSED" || task?.oneSTaskResolution || task.assistant;

    useEffect(() => {
      uploadedFiles.length ? setIsSaveBtnVisible(true) : setIsSaveBtnVisible(false);
    }, [uploadedFiles]);

    return (
      <LoaderRenderWithCondition condition={isLoadingTask}>
        <>
          {isModal ? (
            <HeaderModal
              title="task"
              leftSide={{ onPressClose: handleCloseWrapper, isHideCancel: true }}
              rightSide={isFromOneS ? undefined : { title: modalRightBtnTitle, onPress: onSaveHandler, styleText: { fontWeight: "700" } }}
              isEdit={!!isEdit}
              themeTextColor={theme.text.main}
              styleContainer={{ backgroundColor: theme.background.main }}
            />
          ) : (
            <HeaderTablet
              isFromOneS={isFromOneS}
              isPersonalTask={isPersonalTask}
              isSaveBtnVisible={isSaveBtnVisible}
              onSave={onSaveHandler}
              onClose={handleCloseWrapper}
              task={task}
            />
          )}
          <Container>
            <OneSTaskStatusHeader isFromOnes={isFromOneS} oneSTaskStatus={task.oneSTaskType} />
            <TitleBlock isPersonalTask={isPersonalTask}>
              <RenderWithCondition condition={isRenderStatus}>
                <StatusWrap isShowLine={stylesWrapperStatus}>
                  <ViewSaveStatus task={task} />
                  <RenderWithCondition condition={canEdit}>
                    <Button onClick={() => setIsVisibleActions(true)}>
                      <SvgDotes fill={Colors.LIGHT.white} />
                    </Button>
                  </RenderWithCondition>
                </StatusWrap>
              </RenderWithCondition>

              <TitleLine>
                <TextFont type="bold" size={24} weight="700" color={Colors.LIGHT.white}>
                  {task.name}
                </TextFont>
                <RenderWithCondition condition={task.status === "OPEN" && !task?.oneSTaskResolution && canEdit && !task.assistant}>
                  <Button onClick={() => setIsVisibleActions(true)}>
                    <SvgDotes fill={Colors.LIGHT.white} />
                  </Button>
                </RenderWithCondition>
                <ModalRenderWithCondition condition={isVisibleActions}>
                  <Actions
                    task={task}
                    closeOverModal={handleClose}
                    close={() => setIsVisibleActions(false)}
                    isPersonalTask={isPersonalTask}
                    openFilePicker={openFilePickerWrapper}
                    isFromOneS={task.isFromOneS ?? false}
                    handleTakeBack={handleTakeBack}
                  />
                </ModalRenderWithCondition>
              </TitleLine>
              <RenderWithCondition condition={!!taskDescription}>
                <Description text={taskDescription} isPersonalTask={isPersonalTask} />
              </RenderWithCondition>
            </TitleBlock>
            <Main>
              <Tags>
                <LineTags>
                  <Tag>
                    <TextFont>
                      <FormattedMessage id={task.priority.toLocaleLowerCase()} />
                    </TextFont>
                  </Tag>
                  <RenderWithCondition condition={task.tag}>
                    <Tag>
                      <TextFont>{`#${task.tag?.name}`}</TextFont>
                    </Tag>
                  </RenderWithCondition>

                  <RenderWithCondition condition={task.plannedEndDate || task.startDate || task.createdDate}>
                    <Tag>
                      <TextFont>{generateDate}</TextFont>

                      <RenderWithCondition condition={task.plannedEndDate && !task.startDate}>
                        <SvgDeadlineCustom />
                      </RenderWithCondition>
                    </Tag>
                  </RenderWithCondition>
                </LineTags>
                <RenderWithCondition condition={task.project}>
                  <Tag top="10px">
                    <TextFont>
                      <RenderWithCondition condition={task.project?.shortName}>
                        <TextFont weight="700" align="center">
                          {`${task.project?.shortName} `}
                        </TextFont>
                      </RenderWithCondition>
                      <TextFont align="center">{task.project?.name}</TextFont>
                    </TextFont>
                  </Tag>
                </RenderWithCondition>
              </Tags>

              <RenderWithCondition condition={isDelegateBtnVisible}>
                <Delegate task={task} disable={{ value: !isEdit, press: onPressDisable }} />
              </RenderWithCondition>

              <RenderWithCondition condition={linkedTask}>
                <LinkedWrap>
                  <Item
                    meetingTaskStyles
                    index={0}
                    item={linkedTask}
                    press={() => setIsControlTaskDetailedScreenVisible(true)}
                    onRightClick={() => ""}
                  />
                </LinkedWrap>
              </RenderWithCondition>

              <RenderWithCondition condition={!!task.businessGoal && !controlledTask}>
                <GoalBlock>
                  <TextFont type="bold" size={14} weight="700">
                    Цель задачи
                  </TextFont>
                  <TextFont size={16} weight="400">
                    {task.businessGoal?.description}
                  </TextFont>
                </GoalBlock>
              </RenderWithCondition>

              {WorkDaysComponent}
              <TaskMeetings
                allowMeetingsAttach={canEdit}
                disable={{ value: !isEdit, press: onPressDisable }}
                taskId={task.id}
                enableAddButton={false}
              />

              <FileAttachments
                disable={{ value: !isEdit, press: onPressDisable }}
                enableAddButton={false}
                openFilePicker={openFilePicker}
                allFiles={allFiles}
                uploadedFiles={uploadedFiles}
                handleFileDownload={handleFileDownload}
                deleteFile={deleteFile}
              />

              <EventComments
                eventType={EventCommentsTypes.Task}
                eventId={task.id}
                comments={commentsData ?? []}
                isPersonalTask={isPersonalTask}
                allowCommentAddition={canEdit}
              />

              <RenderWithCondition condition={!isFromOneS && canEdit}>
                {task.status === "COMPLETED" || task.status === "CLOSED" || task.status === "PAUSED" ? (
                  <ButtonReady isPersonalTask={isPersonalTask} backColor={Colors.LIGHT.lighGrey} onClick={handleReturnToWork}>
                    <SvgReturnWork fill={Colors.LIGHT.grey500} />
                    <TextFont size={18} color={Colors.LIGHT.text.grey}>
                      <FormattedMessage id="returnToWork" />
                    </TextFont>
                  </ButtonReady>
                ) : (
                  <ButtonReady isPersonalTask={isPersonalTask} onClick={handleComplete}>
                    <SvgDone fill={Colors.LIGHT.white} />
                    <TextFont size={18} color={Colors.LIGHT.white}>
                      <FormattedMessage id="readyTask" />
                    </TextFont>
                  </ButtonReady>
                )}
              </RenderWithCondition>

              <RenderWithCondition condition={isFromOneS}>
                <Resolution1CActions
                  oneSTaskResolution={task.oneSTaskResolution}
                  availableResulutions={task.oneSTaskAvailableResolutions ?? []}
                  taskId={task.id}
                  oneSTaskStatus={task?.oneSTaskStatus ?? OneSResolutions.AGREED_WITH_REMARKS}
                />
              </RenderWithCondition>
            </Main>
          </Container>
          <RenderWithCondition condition={isVisibleAddDay}>
            <PlanningWork
              isVisible={isVisibleAddDay}
              setIsVisible={setIsVisibleAddDay}
              isTime={Boolean(task.timeEnabled)}
              startDate={task.startDate}
              startDays={task.workDays}
              tempObject={tempWorkDay}
              onSave={handleSaveAddDays}
              keyTitle="workDay"
              deadline={task.plannedEndDate}
              timeEnabled={task.timeEnabled}
            />
          </RenderWithCondition>
          <ModalRenderWithCondition condition={isControlTaskDetailedScreenVisible}>
            <ModalTask
              isVisible={isControlTaskDetailedScreenVisible}
              setIsVisible={setIsControlTaskDetailedScreenVisible}
              id={linkedTask?.id ?? ""}
            />
          </ModalRenderWithCondition>
          {WorkDaysModals}
        </>
      </LoaderRenderWithCondition>
    );
  },
);

const Container = styled.div`
  height: 90%;
  overflow-y: auto;
`;
const TitleBlock = styled.div<{ isPersonalTask: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 17px 12px 30px 12px;
  background-color: ${(props) => (props.isPersonalTask ? props.theme.extra.darkPurple : props.theme.extra.background.darkGreen)};
`;

const StatusWrap = styled.div<{ isShowLine?: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ isShowLine }) =>
    isShowLine &&
    `
    border-bottom: 1px solid ${Colors.LIGHT.asphalt};
    margin-bottom: 10px;
   }
  `}
`;
const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Button = styled.button``;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.extra.background.main};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 12px;
  margin-top: -15px;
  gap: 15px;
`;
const Tags = styled.div`
  width: 100%;
`;
const LineTags = styled.div`
  display: flex;
`;
const Tag = styled.div<{ top?: string }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 7.5px 5px;
  border-radius: 7px;
  background-color: ${(props) => props.theme.extra.white};
  margin-top: ${(props) => (props.top ? props.top : "0")};
  margin-right: 10px;
`;
const LinkedWrap = styled.div`
  margin-top: 10px;
`;
const GoalBlock = styled.div`
  flex: 1;
  border-bottom-color: ${(props) => props.theme.extra.lighGrey};
  border-bottom-width: 1px;
`;
const SvgDeadlineCustom = styled(SvgDeadline)`
  margin-left: 5px;
`;
const ButtonReady = styled.button<{ isPersonalTask: boolean; backColor?: string }>`
  display: flex;
  border-radius: 10px;
  margin-top: 20px;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${(props) =>
    props.isPersonalTask ? props.theme.extra.darkPurple : props.backColor ? props.backColor : props.theme.extra.background.green};
`;

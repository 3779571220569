import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { selectSpecialTag } from "@store/tag";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";

import { CommonFields } from "../types";

import { ModalTag } from "./ModalTag";

interface IProps extends CommonFields {
  id: string;
}

export const Tag: FC<IProps> = ({ id, handleData }) => {
  const [isVisibleTag, setIsVisibleTag] = useState(false);

  const currentTag = useSelector(selectSpecialTag(id));

  return (
    <>
      <ButtonBillet onClick={() => setIsVisibleTag(true)}>
        <TextFont size={16}>
          <FormattedMessage id="tag" />
        </TextFont>
        <IconWrap>
          <TextFont color={Colors.LIGHT.placeholder.main}>{currentTag?.name}</TextFont>
          <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
        </IconWrap>
      </ButtonBillet>
      <RenderWithCondition condition={isVisibleTag}>
        <ModalTag isVisible={isVisibleTag} setIsVisible={setIsVisibleTag} currentTagId={currentTag?.id} setTag={handleData} />
      </RenderWithCondition>
    </>
  );
};

const ButtonBillet = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 8px;
  width: 100%;
`;
const IconWrap = styled.div`
  display: flex;
  align-items: center;
`;

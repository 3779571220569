import React, { FC, memo, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import { toFormatDate, toFormatISO } from "@utils/toFormatTime";

import { Modal } from "../modal/Modal";

interface IRow {
  hour: number;
  width: number;
  currentTime?: Dayjs;
  isHide?: boolean;
}

export const Row: FC<IRow> = memo(function Row({ hour, width, currentTime, isHide }) {
  const currentMinute = currentTime?.minute();

  const [isVisible, setIsVisible] = useState(false);
  const [joinTime, setJoinTime] = useState({});

  const dateNow = useSelector(selectCurrentDate);

  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);

  const handlePress = () => {
    if (hour === 24) return;

    const startTime = dateNow.hour(hour).minute(0).second(0);
    const calculatedEndTime =
      startTime.format("HH") === "23" ? toFormatISO(startTime.endOf("hour")) : toFormatISO(startTime.add(1, "hour"));

    setJoinTime({ startTime: toFormatISO(startTime), endTime: calculatedEndTime });
    setIsVisible(true);
  };

  const formatCurrentMinute = (currentMinute ?? 0) < 10 ? `0${currentMinute}` : currentMinute;
  const formatHour = useMemo(() => {
    let tempHour = hour;

    if (tempHour === 24) tempHour = 0;

    return tempHour < 10 ? `0${tempHour}` : tempHour;
  }, [hour]);
  const isCurrentHour = hour === currentTime?.hour() && toFormatDate(dateNow) === toFormatDate(dayjs());

  return (
    <ContentWrap isHide={isHide}>
      <TextTime size={12} color={Colors.LIGHT.text.grey400}>{`${formatHour}:00`}</TextTime>
      <ButtonItem disabled={isHide} onClick={handlePress}>
        <LineTime />

        <RenderWithCondition condition={isTightMode}>
          <LineTimeTightMode />
        </RenderWithCondition>
      </ButtonItem>

      <RenderWithCondition condition={isCurrentHour && currentTime}>
        <BlockCurrentTime top={Math.ceil(currentMinute / 2.5)}>
          <TextFont size={10} color={Colors.LIGHT.red}>{`${formatHour}:${formatCurrentMinute}`}</TextFont>
          <LineCurrentTime width={width} />
        </BlockCurrentTime>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} meetId={""} data={joinTime} setIsVisible={setIsVisible} />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
}, arePropsEqual);

const ContentWrap = styled.div<{ isHide: boolean }>`
  display: flex;
  height: 38px;
  position: relative;
  ${({ isHide }) => isHide && "opacity: 0.3"}
`;
const TextTime = styled(TextFont)`
  line-height: 20px;
  text-align: center;
  width: 50px;
  margin-top: -8px;
  height: 100%;
`;
const ButtonItem = styled.button`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
`;
const LineTime = styled.div`
  height: 0.9px;
  width: 100%;
  background-color: ${Colors.LIGHT.grey200};
  z-index: 2;
`;
const LineCurrentTime = styled.div<{ width: number }>`
  height: 0.9px;
  width: ${({ width }) => width}px;
  background-color: ${Colors.LIGHT.red};
`;
const LineTimeTightMode = styled.div`
  position: absolute;
  height: 0.5px;
  z-index: 10;
  width: 100%;
  background-color: ${Colors.LIGHT.lighGrey};
  left: 50px;
  top: 0;
`;
const BlockCurrentTime = styled.div<{ top: number }>`
  position: absolute;
  display: flex;
  z-index: 2;
  align-items: center;
  width: 100%;
  left: 17px;
  top: ${({ top }) => top}px;
  gap: 8px;
`;

import React, { FC } from "react";

import { useIntl } from "react-intl";
import styled from "styled-components";

import { Colors } from "@theme/colors";
import { maxLinesInput } from "@utils";

import { ICommonFields } from "../types";

interface IProps extends ICommonFields {
  name: string;
  description: string;
  autoFocus: boolean;
}

export const Inputs: FC<IProps> = ({ handleData, name, description, autoFocus }) => {
  const handleChangeText = (text: string, name: string) => {
    if (maxLinesInput(text)) {
      handleData(name, text);
    }
  };

  const textAreaAdjust = (element) => {
    element.target.style.height = "1px";
    element.target.style.height = `${element.target.scrollHeight}px`;
  };

  const placeholderMeetName = useIntl().formatMessage({ id: "meetingName" });
  const placeholderMeetDescription = useIntl().formatMessage({ id: "meetingDescroption" });

  return (
    <>
      <TextareaName
        placeholder={placeholderMeetName}
        value={name}
        onChange={(e) => handleChangeText(e.target.value, "name")}
        autoFocus={autoFocus}
        onKeyUp={textAreaAdjust}
        maxLength={100}
      />
      <TextareaDescription
        placeholder={placeholderMeetDescription}
        value={description}
        onChange={(e) => handleData("description", e.target.value)}
        onKeyUp={textAreaAdjust}
      />
    </>
  );
};

const TextareaInput = styled.textarea`
  background-color:  ${Colors.LIGHT.white}
  border-radius: 9px;
  padding: 16px;
  padding-bottom: 8px;
  font-size: 18px;
  width: 92%;
  min-height: 56px;
  margin-top: 8px;
`;

const TextareaName = styled(TextareaInput)`
  margin: 8px 0;
  padding-top: 16px;
`;
const TextareaDescription = styled(TextareaInput)`
  padding-top: 16px;
`;

import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ModalRenderWithCondition } from "@hoc";
import { IMeet, IMeetingRoom } from "@interfaces/meet.interface";
import { useGetOccupancyRoomsQuery, useUpdateSingleOrSerieMeetingMutation } from "@services/meetApi";
import { Calendar } from "@shared/planningWork";
import { resetExternalUsers } from "@store/externalUsers/slice";
import { selectCurrentDate } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { EditTimerOptions } from "@ui/TimePicker";
import { calendar, isTablet, toFormatDate, toFormatISO } from "@utils";

import { Time } from "../../activityParticipants/components";
import { COEFFICIENT_COLOR_GENERATION } from "../../activityParticipants/components/Activity/constants";
import { useData } from "../../activityParticipants/useData";

import { MeetingRooms } from "./components/MeetingRooms";
import { OccupancyMeetingRooms } from "./components/OccupancyMeetingRooms";

export interface IStartData {
  data: IMeet;
  handleTime: (type: "startTime" | "endTime", arg: any) => void;
  startTime: string;
  endTime: string;
  handleData: (arg: IMeet) => void;
}
export interface IMeetingRoomWithColor extends IMeetingRoom {
  colorAvatar: string;
}
interface IProps {
  close: () => void;
  startData: IStartData;
  allowEdit: boolean;
  dataMeetingRooms?: IMeetingRoom[];
  titleModal: string;
  titleTable: string;
  setIsVisiblePlaces: () => void;
  setSelectedData: (data: IMeetingRoom | null) => void;
}

export const MeetingRoomsBooking: FC<IProps> = ({
  close,
  startData,
  allowEdit,
  dataMeetingRooms,
  titleModal,
  setIsVisiblePlaces,
  setSelectedData,
}) => {
  const dispatch = useDispatch();
  const selectedDay = useSelector(selectCurrentDate);

  const {
    startTime,
    endTime,
    data,
    handleTime,
    isEdit,
    isVisbleCalendar,
    setIsVisbleCalendar,
    participantLight,
    meetingDurationMins,
    handleData,
  } = useData(startData);

  const meetChangeMode = useRef<"serie" | "event">();

  const [isChangeModeSelectorVisible, setIsChangeModeSelectorVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs(startTime));
  const [selectedRoom, setSelectedRoom] = useState<IMeetingRoom | null>(data.meetingRoom);

  const [updateSingleMeetOrSerie] = useUpdateSingleOrSerieMeetingMutation();

  const isMeetingRoomChanged = useMemo(() => !isEqual(data.meetingRoom, selectedRoom), [data, selectedRoom]);

  const { data: occupancyRoomsInTimeMeet } = useGetOccupancyRoomsQuery({
    startTime: toFormatISO(dayjs(startTime).date(currentDate.date())),
    endTime: toFormatISO(dayjs(endTime).date(currentDate.date())),
    externalIds: dataMeetingRooms?.map((item) => item.externalId),
  });

  const handleUserChoice = (mode: "serie" | "event") => {
    meetChangeMode.current = mode;
    setIsChangeModeSelectorVisible(false);
    handleSave();
  };
  const handleSelectedRoom = (data: IMeetingRoom) => {
    setSelectedRoom(data.id === selectedRoom?.id ? null : data);
  };

  const pressSave = () => {
    if (data?.repeat || data?.parentEvent) {
      setIsChangeModeSelectorVisible(true);
    }
    if (!data?.repeat && !data?.parentEvent) {
      handleSave();
    }
  };

  const handleSave = async () => {
    let targetEventId = data.id;

    if (meetChangeMode.current === "serie" && data?.parentEvent?.id) {
      targetEventId = data?.parentEvent?.id;
    }
    updateSingleMeetOrSerie({
      id: targetEventId,
      data: {
        startTime,
        endTime,
        meetingRoomId: selectedRoom?.id ?? null,
      },
      date: dayjs(selectedDay).format("YYYY-MM-DD"),
      changeSerie: meetChangeMode.current === "serie",
      repeat: Boolean(data.repeat),
      parentEvent: data.parentEvent,
    });

    if (!data.id) {
      setSelectedData(selectedRoom ?? null);
    }

    startData.handleData(data);
    startData.handleTime("startTime", startTime);
    startData.handleTime("endTime", endTime);
    if (data.id) {
      close();
    }
  };

  const handleDate = (dateWeek: Dayjs) => {
    const day = dateWeek.date();
    const month = dateWeek.month();
    const year = dateWeek.year();
    const tempStartTime = toFormatISO(dayjs(startTime).date(day).month(month).year(year));

    handleTime("startTime", tempStartTime);
    handleTime("endTime", toFormatISO(dayjs(endTime).date(day).month(month).year(year)));
    setCurrentDate(dayjs(tempStartTime));
    setIsVisbleCalendar(false);
  };

  const handleHourSlotPress = (hour: number) => {
    if (hour === 24 || !allowEdit) return;

    const selectedDateTime = dayjs(currentDate.format("YYYY-MM-DD")).set("hour", hour);
    let calculatedEndTime = selectedDateTime.add(meetingDurationMins, "minutes");
    const updatedStartTime = toFormatISO(selectedDateTime);

    if (calculatedEndTime.isAfter(selectedDateTime, "day") && meetingDurationMins > 55) {
      calculatedEndTime = dayjs(selectedDateTime).endOf("day");
    }

    const updatedEndTime = toFormatISO(calculatedEndTime);

    handleTime("startTime", updatedStartTime);
    handleTime("endTime", updatedEndTime);
  };

  const closeWrapper = () => {
    dispatch(resetExternalUsers());
    close();
  };

  const generateColor = () => {
    const randomColor = Math.floor(Math.random() * COEFFICIENT_COLOR_GENERATION)
      .toString(16)
      .padStart(6, "0");
    return `#${randomColor}`;
  };

  const listMeetingRoomsWithColors: IMeetingRoomWithColor[] = useMemo(
    () => dataMeetingRooms?.map((item) => ({ ...item, colorAvatar: generateColor() })) ?? [],
    [dataMeetingRooms],
  );

  const renderBtnHeader = (type: "save" | "cancel") => {
    const config = {
      save: {
        bg: Colors.LIGHT.background.green,
        color: Colors.LIGHT.white,
        press: pressSave,
      },
      cancel: {
        bg: Colors.LIGHT.lighGrey,
        color: Colors.LIGHT.text.grey,
        press: setIsVisiblePlaces,
      },
    };

    return (
      <ButtonWrap>
        <ButtonHeader backColor={config[type].bg} onClick={config[type].press}>
          <TextFont color={config[type].color} size={16} weight="700">
            <FormattedMessage id={type} />
          </TextFont>
        </ButtonHeader>
      </ButtonWrap>
    );
  };

  return (
    <ModalUI id="modalActivityParticipant" isFullWidth={isTablet} scrollEnable={false} onClose={closeWrapper} isVisible>
      <HeaderModal
        title={titleModal}
        leftSide={{ onPressClose: closeWrapper, isHideCancel: true, element: renderBtnHeader("cancel") }}
        rightSide={{ element: renderBtnHeader("save") }}
        styleContainer={{ borderBottomWidth: 0 }}
        isEdit={isEdit || isMeetingRoomChanged}
      />

      <ContentWrap>
        <ContentDate>
          <ButtonCalendar onClick={() => allowEdit && setIsVisbleCalendar(!isVisbleCalendar)}>
            <TextFont size={16}>
              {`${dayjs(startTime).format("DD.MM.YY")}, ${calendar.getWeekDayRu(dayjs(startTime).day())?.toLowerCase()}`}
            </TextFont>
          </ButtonCalendar>
          <TimeBlock>
            <Time value={startTime} handleTime={handleTime} type={EditTimerOptions?.StartTime} disablePress={!allowEdit} />
            <Time value={endTime} handleTime={handleTime} type={EditTimerOptions?.EndTime} disablePress={!allowEdit} />
          </TimeBlock>
        </ContentDate>
        <ModalRenderWithCondition condition={isVisbleCalendar}>
          <CalendarWrap>
            <Calendar
              currentDay={dayjs(startTime).format("YYYY-MM-DD")}
              startDay={data?.date}
              press={handleDate}
              deadline={data?.repeat?.endTime ? toFormatDate(dayjs(data?.repeat?.endTime)) : null}
              disable={{ style: false, button: false }}
            />
          </CalendarWrap>
        </ModalRenderWithCondition>

        <ContentMeetRooms>
          <MeetingRooms
            data={listMeetingRoomsWithColors}
            occupancyRooms={occupancyRoomsInTimeMeet}
            handleSelectedRoom={handleSelectedRoom}
            selectedRoom={selectedRoom}
          />
          <OccupancyMeetingRooms
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            participantLight={participantLight}
            participants={data?.participants ?? []}
            time={{ startTime, endTime }}
            meetingRooms={dataMeetingRooms ?? []}
            handleHourSlotPress={handleHourSlotPress}
          />
        </ContentMeetRooms>
      </ContentWrap>

      <ModalRenderWithCondition condition={isChangeModeSelectorVisible}>
        <NotificationConfirm
          phraseId="oneMeetOrAll"
          phraseOkId="allSeries"
          phraseCancelId="oneMeet"
          onOk={() => handleUserChoice("serie")}
          onCancel={() => handleUserChoice("event")}
          isOpen
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const ButtonWrap = styled.div`
  padding: 0 20px;
`;
const ContentWrap = styled.div`
  padding: 0 12px;
`;
const ContentDate = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.LIGHT.white};
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
`;
const TimeBlock = styled.div`
  display: flex;
  gap: 7px;
`;
const ButtonCalendar = styled.button`
  background-color: ${Colors.LIGHT.background.main};
  border-radius: 5px;
  padding: 3px 5px 3px 9px;
  justify-content: center;
`;
const ButtonHeader = styled.button<{ backColor: string }>`
  height: 44px;
  padding: 0 22px;
  border-radius: 10px;
  background-color: ${({ backColor }) => backColor};
`;
const CalendarWrap = styled.div`
  display: flex;
  padding-top: 15px;
`;
const ContentMeetRooms = styled.div`
  display: grid;
  padding-top: 15px;
  max-height: 80vh;
  grid-template-columns: 40% 60%;
  grid-gap: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;

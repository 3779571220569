import React, { FC, useEffect, useMemo, useRef } from "react";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { IMeetParticipants, IMeetingRoom } from "@interfaces/meet.interface";
import { useGetOccupancyRoomsQuery } from "@services/meetApi";
import { Colors } from "@theme/colors";
import { ArrowSvg, TextFont } from "@ui";
import { calendar, toFormatISO } from "@utils";

import { HOURTOSCROLL, arrHours } from "../../../../../constants";
import { BlockTime } from "../../../activityParticipants/components/Activity/components/BlockTime";
import { Row } from "../../../activityParticipants/components/Activity/components/Row";
import { RowParticipant } from "../../../activityParticipants/components/Activity/components/RowParticipant";
import { WIDTH_BLOCK } from "../../../activityParticipants/components/Activity/constants";

interface IProps {
  time: { startTime: string; endTime: string };
  currentDate: Dayjs;
  setCurrentDate: (arg: Dayjs) => void;
  participants: IMeetParticipants[];
  participantLight: string;
  meetingRooms?: IMeetingRoom[];
  handleHourSlotPress: (hour: number) => void;
}

export const OccupancyMeetingRooms: FC<IProps> = ({
  currentDate,
  setCurrentDate,
  participants,
  participantLight,
  time,
  meetingRooms,
  handleHourSlotPress,
}) => {
  const refView = useRef(null);
  const { data: occupancyRooms } = useGetOccupancyRoomsQuery({
    startTime: toFormatISO(currentDate.hour(0)),
    endTime: toFormatISO(currentDate.hour(23)),
    externalIds: meetingRooms.map((item) => item.externalId),
  });

  useEffect(() => {
    refView?.current?.scrollTo(HOURTOSCROLL * WIDTH_BLOCK, 0);
  }, [refView]);

  const isLightColumnNumber = useMemo(
    () => participants?.findIndex((i) => i.userId === participantLight),
    [participantLight, participants?.length],
  );

  return (
    <ContentWrap>
      <Content>
        <Calendar>
          <Header>
            <button onClick={() => setCurrentDate(currentDate.subtract(1, "day"))}>
              <ArrowSvg fill={Colors.LIGHT.text.main} type="left" />
            </button>
            <TextFont type="bold" size={16} weight="700">{`${calendar.getWeekDayFull(currentDate.day())}, ${currentDate.format(
              "DD MMMM",
            )}`}</TextFont>
            <button onClick={() => setCurrentDate(currentDate.add(1, "day"))}>
              <ArrowSvg fill={Colors.LIGHT.text.main} type="right" />
            </button>
          </Header>

          <OccupancyWrap ref={refView}>
            <Occupancy>
              <Occupied>
                {occupancyRooms &&
                  occupancyRooms?.map((meetingRoom) => (
                    <RowParticipant key={meetingRoom?.externalId} events={meetingRoom?.meetings ?? []} />
                  ))}
              </Occupied>
              <Rows>
                {arrHours?.map((hour, index) => (
                  <ButtonRow isPointer={hour === 24} onClick={() => handleHourSlotPress(hour)}>
                    <Row rows={meetingRooms?.length} isLightColumnNumber={isLightColumnNumber} key={index} hour={hour} date={currentDate} />
                  </ButtonRow>
                ))}
              </Rows>
              <BlockTime time={time} />
            </Occupancy>
          </OccupancyWrap>
        </Calendar>
      </Content>
    </ContentWrap>
  );
};

const ContentWrap = styled.section`
  margin-top: 20px;
`;
const Content = styled.div`
  display: flex;
`;
const Calendar = styled.div`
  width: 95%;
  background-color: ${Colors.LIGHT.white};
  margin-top: 10px;
  margin-left: 5px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;
const OccupancyWrap = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;
const Occupancy = styled.div`
  position: relative;
  width: 100%;
`;
const Occupied = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 67px;
`;
const Rows = styled.div`
  display: flex;
`;
const ButtonRow = styled.div<{ isPointer: boolean }>`
  ${({ isPointer }) =>
    isPointer &&
    `
    cursor: pointer;
 `}
`;

import React, { Dispatch, FC, SetStateAction, useState, useMemo } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { setOnesTaskResolution } from "@store/businessTask/api";
import { Colors } from "@theme/colors";
import { ModalUI, HeaderModal, TextFont } from "@ui";
import { isTablet } from "@utils";

import { IResoutionButton, OneSResolutions } from "./constants";

interface IProps {
  isVisible: boolean;
  buttonMeta: IResoutionButton | null;
  taskId: string;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const AddResolutionCommentModal: FC<IProps> = ({ taskId, isVisible, buttonMeta, setIsVisible }) => {
  const [comment, setComment] = useState("");
  const isEdit = useMemo(
    () =>
      comment.length ||
      buttonMeta?.type === OneSResolutions.AGREED ||
      buttonMeta?.type === OneSResolutions.COMPLETE ||
      buttonMeta?.type === OneSResolutions.EXECUTED ||
      buttonMeta?.type === OneSResolutions.FAMILIARIZED,
    [comment],
  );
  const placeholder = useIntl().formatMessage({ id: "yourRemarks" });
  const isLoading = false;
  const dispatch = useDispatch();

  const handlePressSave = () => {
    if (buttonMeta?.type) {
      dispatch(setOnesTaskResolution({ taskId, resolution: buttonMeta.type, comment }));
    }
  };

  return (
    <ModalUI isVisible={isVisible && Boolean(buttonMeta)} backdropOpacity onBack={() => setIsVisible(false)}>
      <RenderWithCondition condition={isTablet}>
        <HeaderModal title=" " leftSide={{ onPressClose: () => setIsVisible(false), isHideCancel: true }} />
      </RenderWithCondition>
      <Container>
        <TextareaComment
          placeholder={placeholder}
          value={comment}
          autoFocus={true}
          maxLength={255}
          onChange={(e) => setComment(e.target.value)}
        />
        <ButtonAction disabled={!isEdit} isEdit={!!isEdit} onClick={handlePressSave}>
          <LoaderButtonRenderWithCondition condition={isLoading}>
            <TextFont size={18} weight="700" color={Colors.LIGHT.white}>
              <FormattedMessage id={buttonMeta?.textId} defaultMessage={buttonMeta?.defaultMessage} />
            </TextFont>
          </LoaderButtonRenderWithCondition>
        </ButtonAction>
      </Container>
    </ModalUI>
  );
};

const Container = styled.div`
  padding: 20px 10px;
`;
const TextareaComment = styled.textarea`
  background-color: ${Colors.LIGHT.white};
  border-radius: 9px;
  font-size: 18px;
  min-height: 150px;
  border: 1px solid ${Colors.LIGHT.grey};
  width: 100%;
  margin: 8px 0;
  padding: 16px 8px 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;
const ButtonAction = styled.button<{ isEdit: boolean }>`
  margin-bottom: 20px;
  background-color: ${Colors.LIGHT.text.accent};
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  margin-top: 15px;
  ${(props) => !props.isEdit && `background-color: ${Colors.LIGHT.lighGrey};`}
  padding: 13px;
`;

import React, { FC, useEffect, useMemo, useRef } from "react";

import { Dayjs } from "dayjs";
import styled from "styled-components";

import { IMeetParticipants } from "@interfaces/meet.interface";
import { useGetMeetingAttendanceQuery } from "@services/meetApi";
import { Colors } from "@theme/colors";
import { ArrowSvg, TextFont } from "@ui";
import { calendar, toFormatISO } from "@utils";

import { HOURTOSCROLL, arrHours } from "../../../../../constants";

import { BlockTime } from "./components/BlockTime";
import { Row } from "./components/Row";
import { RowParticipant } from "./components/RowParticipant";
import { WIDTH_BLOCK } from "./constants";

interface IProps {
  time: { startTime: string; endTime: string };
  currentDate: Dayjs;
  setCurrentDate: (arg: Dayjs) => void;
  participants: IMeetParticipants[];
  participantLight: string;
  handleHourSlotPress: (hour: number) => void;
  meetId?: string;
}

export const Activity: FC<IProps> = ({
  currentDate,
  setCurrentDate,
  participants,
  participantLight,
  time,
  handleHourSlotPress,
  meetId,
}) => {
  const refView = useRef(null);

  const { data: attendance } = useGetMeetingAttendanceQuery({
    startTime: toFormatISO(currentDate.hour(0)),
    endTime: toFormatISO(currentDate.hour(23)),
    userIds: participants?.map((i) => i.userId),
    excludedMeetingId: meetId ?? "",
  });

  useEffect(() => {
    refView?.current?.scrollTo(HOURTOSCROLL * WIDTH_BLOCK, 0);
  }, [refView]);

  const isLightColumnNumber = useMemo(
    () => participants.findIndex((i) => i.userId === participantLight),
    [participantLight, participants.length],
  );

  return (
    <ContentWrap>
      <Content>
        <CalendarWrap>
          <BlockHeader>
            <button onClick={() => setCurrentDate(currentDate.subtract(1, "day"))}>
              <ArrowSvg fill={Colors.LIGHT.text.main} type="left" />
            </button>
            <TextFont type="bold" size={16} weight="700">{`${calendar.getWeekDayFull(currentDate.day())}, ${currentDate.format(
              "DD MMMM",
            )}`}</TextFont>
            <button onClick={() => setCurrentDate(currentDate.add(1, "day"))}>
              <ArrowSvg fill={Colors.LIGHT.text.main} type="right" />
            </button>
          </BlockHeader>

          <BlockActivity ref={refView}>
            <ActivityWrap>
              <BlockAttendance>
                {attendance?.map((i) => (
                  <RowParticipant key={i.userId} events={i.meetings} />
                ))}
              </BlockAttendance>
              <BlockTable>
                {arrHours.map((hour, index) => (
                  <RowTab isNotCursor={hour === 24} onClick={() => handleHourSlotPress(hour)}>
                    <Row rows={participants.length} isLightColumnNumber={isLightColumnNumber} key={index} hour={hour} date={currentDate} />
                  </RowTab>
                ))}
              </BlockTable>
              <BlockTime time={time} />
            </ActivityWrap>
          </BlockActivity>
        </CalendarWrap>
      </Content>
    </ContentWrap>
  );
};

const ContentWrap = styled.section`
  margin-top: 20px;
`;
const Content = styled.div`
  display: flex;
`;
const CalendarWrap = styled.div`
  width: 95%;
  background-color: ${Colors.LIGHT.white};
  margin-top: 10px;
  margin-left: 5;
`;
const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;
const BlockActivity = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;
const ActivityWrap = styled.div`
  position: relative;
  width: 100%;
`;
const BlockAttendance = styled.div`
  position: absolute;
  top: 67px;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
const BlockTable = styled.div`
  display: flex;
  z-index: 100;
`;
const RowTab = styled.div<{ isNotCursor: boolean }>`
  cursor: pointer;
  ${({ isNotCursor }) => isNotCursor && "cursor: default"};
`;

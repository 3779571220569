import React, { useMemo } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { TTypePriorityTask } from "@interfaces/businessGoals.interface";
import { TextFont } from "@ui/TextFont";

const priorities = [
  { priority: "URGENT", title: "urgent" },
  { priority: "IMPORTANT", title: "important" },
  { priority: "CONTACT", title: "contact" },
] as { priority: TTypePriorityTask; title: string }[];

interface IPriority {
  priority: TTypePriorityTask;
  setPriority: (name: string, value: TTypePriorityTask) => void;
}

export const Priority = ({ priority, setPriority }: IPriority) => (
  <ContentWrap>
    <TextFont type="bold" size={18} weight="700">
      <FormattedMessage id="taskPriority" />
    </TextFont>
    <ButtonsWrap>
      {priorities.map((item) => (
        <Button
          key={item.priority}
          isBorder={item.priority === priority}
          onClick={() => item.priority !== priority && setPriority("priority", item.priority)}
        >
          <TextFont size={18}>
            <FormattedMessage id={item.title} />
          </TextFont>
        </Button>
      ))}
    </ButtonsWrap>
  </ContentWrap>
);

const ContentWrap = styled.section`
  flex: 1;
`;
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const Button = styled.button<{ isBorder: boolean }>`
  width: 32%;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
  background-color: ${({ theme }) => theme.extra.lighGrey};
  ${(props) =>
    props.isBorder &&
    `   
        background-color: ${props.theme.extra.lighGrey};
        border: 1px solid ${props.theme.extra.background.green};
       `}
`;
